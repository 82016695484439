import { ComponentProps, FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { GalaxyOneProvider } from '@g1/components'
import { buildMarginCallWidget } from '@galaxy-digital/lending'
import router from 'next/router'
import { selectDefaultEntity } from 'redux/selectors/selectGalaxyUser'
import { selectOktaToken } from 'redux/selectors/selectOktaToken'
import { isLendingAccount } from 'utils/accounts'

const envVars = {
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
  lendingServiceUrl: process.env.NEXT_PUBLIC_LENDING_SERVICE_BASE_URL,
}
const MarginCallBannerAndAlert = buildMarginCallWidget(envVars)

const LendingBanner: FC = () => {
  const accountInfo = useSelector(selectDefaultEntity)?.accountInfo
  const lendingAccounts = accountInfo.filter(isLendingAccount)
  const oktaToken = useSelector(selectOktaToken)

  const navigateToLoanDetail = path => {
    router.push(path, undefined, {
      shallow: true,
    })
  }

  if (lendingAccounts.length > 0) {
    return (
      <GalaxyOneProvider theme="dark">
        {lendingAccounts.map(account => (
          <MarginCallBannerAndAlert
            appType="margin-call-app"
            navigateToLoanDetail={navigateToLoanDetail}
            // @ts-expect-error Fix roles in Lending (Account_Admin -> Admin)
            account={account}
            accountIndex={accountInfo.indexOf(account)}
            oktaToken={oktaToken}
            key={`margin-call-banner-${account.accountId}`}
          />
        ))}
      </GalaxyOneProvider>
    )
  }
  return null
}

export default memo(LendingBanner)
