/* eslint-disable no-param-reassign */
import deepEqual from 'react-fast-compare'
import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/depositAddress'
import { DepositAddressFormValues } from 'redux/models/DepositAddress'

export const initialState: DepositAddressFormValues = {
  acceptedInvalidAddressWarning: false,
  nickname: '',
  currency: null,
  addressType: null,
  network: null,
  subAddress: '',
  subAddressRequiredByUser: true,
  hasAcceptedTerms: false,
  hasAcceptedDirectOwnershipClaims: false,
  addressDuplicateResponse: null,
  // Crypto
  cryptoAddress: '',
  cryptoAddressError: null,
  cryptoAddressValid: false,
  isSupportedByValidator: true,
  // SEN
  senAccountNumber: '',
  senAccountName: '',
  senAccountNumberError: null,
  senAccountNumberValid: false,
  // SIGNET
  signetAddress: '',
  signetAddressError: null,
  // CBIT
  cbitAddress: '',
  cbitAddressError: null,
  // Wire Transfer
  wireTransferFile: null,
}

export const DepositAddressReducer = handleActions<
  DepositAddressFormValues,
  any
>(
  {
    [Type.SET_FORM_VALUE]: (
      state: DepositAddressFormValues,
      { payload }: { payload: { fieldName: string; value: any } },
    ) => {
      const { fieldName, value } = payload
      return produce(state, draft => {
        if (!deepEqual(state[fieldName], value)) {
          draft[fieldName] = value
        }
      })
    },
    [Type.TOGGLE_FORM_VALUE]: (
      state: DepositAddressFormValues,
      { payload }: { payload: { fieldName: string } },
    ) => {
      const { fieldName } = payload
      return produce(state, draft => {
        if (typeof draft[fieldName] !== 'boolean') {
          return
        }

        draft[fieldName] = !draft[fieldName]
      })
    },
    [Type.RESET_ADDRESS_VALUES]: (state: DepositAddressFormValues) => {
      return {
        ...state,
        acceptedInvalidAddressWarning:
          initialState.acceptedInvalidAddressWarning,
        network: initialState.network,
        cryptoAddress: initialState.cryptoAddress,
        addressDuplicateResponse: initialState.addressDuplicateResponse,
        cryptoAddressError: initialState.cryptoAddressError,
        cryptoAddressValid: initialState.cryptoAddressValid,
        isSupportedByValidator: initialState.isSupportedByValidator,
        senAccountNumber: initialState.senAccountNumber,
        senAccountNumberError: initialState.senAccountNumberError,
        senAccountNumberValid: initialState.senAccountNumberValid,
        senAccountName: initialState.senAccountName,
        signetAddress: initialState.signetAddress,
        signetAddressError: initialState.signetAddressError,
        cbitAddress: initialState.cbitAddress,
        cbitAddressError: initialState.cbitAddressError,
        subAddress: initialState.subAddress,
        subAddressRequiredByUser: initialState.subAddressRequiredByUser,
        wireTransferFile: initialState.wireTransferFile,
        hasAcceptedTerms: initialState.hasAcceptedTerms,
        hasAcceptedDirectOwnershipClaims:
          initialState.hasAcceptedDirectOwnershipClaims,
      }
    },
    [Type.RESET_FORM_VALUES]: () => {
      return {
        ...initialState,
      }
    },
  },
  initialState,
)
