import moment from 'moment'
import { forkJoin } from 'rxjs'
import { Type } from 'redux/actions/derivativePricing'
import { ContractArgs } from 'redux/models/DerivativePricing'
import { getKeyedDispatchQueue } from 'utils/dispatch'
import emptyFunction from 'utils/emptyFunction'
import { get } from 'utils/http'
import { toQueryString } from 'utils/QueryString'

const batchedDispatch = getKeyedDispatchQueue()

const baseUrl = process.env.NEXT_PUBLIC_RISK_SERVER_URL as string
type ContractsPricingData = {
  contracts: ContractArgs[]
}
function makeContractsQueryString(params: ContractsPricingData) {
  let queryString = ''
  params.contracts.forEach(params => {
    queryString += toQueryString(params)
  })
  return queryString
}

export const getContractsDataMultiple = (
  legs: ContractArgs[],
  onSuccess = emptyFunction,
  onError = emptyFunction,
) => {
  forkJoin(
    legs.map(leg => getContractsPricingData({ contracts: [leg] }).ds),
  ).subscribe({
    next: res => {
      batchedDispatch('results', {
        type: Type.SET_RESULTS,
        payload: {
          legs: res.map(res => Object.values(res)[0]),
          params: legs,
          ts: moment(),
        },
      })
      onSuccess()
    },
    error: onError,
  })
}

const getContractsPricingData = (
  params: ContractsPricingData,
  onSuccess = emptyFunction,
) => {
  return get(
    baseUrl,
    `options/optionprice?${makeContractsQueryString(params)}`,
    payload => {
      onSuccess(payload)
    },
    ({ response }) => {
      if (response && response.data) {
        alert(response.data.errorMessage)
      } else {
        alert('Backend Error')
      }
    },
    false,
  )
}

export const derivativePricingvaluesFieldMap = {
  Expiry: 'expiration',
  Rate: 'forwardRate',
  Spot: 'spotReference',
  Vol: 'volatility',
}

export const getContractValues = (
  params: {
    pair: string
    strike: number
    expiry: string
    Flag: string
  },
  onSuccess = emptyFunction,
  onError = emptyFunction,
) => {
  return get(
    baseUrl,
    `options/market?${toQueryString(params)}`,
    payload => {
      if (payload) {
        onSuccess(payload)
      }
    },
    onError,
    false,
  )
}
