import { stringify } from 'query-string'
import { Type } from 'redux/actions/portfolioRisk'
import { LendingRiskDataParams } from 'redux/models/PortfolioRisk'
import { getKeyedDispatchQueue } from 'utils/dispatch'
import emptyFunction from 'utils/emptyFunction'
import { get } from 'utils/http'

const baseUrl = process.env.NEXT_PUBLIC_RISK_SERVER_URL as string
const bufferedQueue = getKeyedDispatchQueue()

export const getPortfolioRiskShockData = (
  params: any = {},
  onSuccess = emptyFunction,
) => {
  const data = {
    ...params,
    incr: params.increment,
    increment: undefined,
  }
  delete data.increment
  return get(
    baseUrl,
    `options/shock?${stringify(data)}`,
    payload => {
      bufferedQueue('shockData', {
        type: Type.SET_DATA,
        payload: {
          data: payload,
          key: 'shockData',
        },
      })
      onSuccess(payload)
    },
    null,
    false,
  )
}

export const getContractVolatilitySurface = (
  { contract },
  onSuccess = emptyFunction,
) => {
  return get(
    baseUrl,
    `options/volsurface?asset=${contract}`,
    payload => {
      bufferedQueue('volSurface', {
        type: Type.SET_VOL_SURFACE,
        payload: {
          key: contract,
          data: payload,
        },
      })
      onSuccess(payload)
    },
    null,
    false,
  )
}

export const getLendingRiskVarianceData = (onSuccess = emptyFunction) => {
  return get(
    baseUrl,
    'lending/var',
    payload => {
      Object.keys(payload).forEach(key => {
        payload[key].id = key
      })
      bufferedQueue('loansVariance', {
        type: Type.SET_DATA,
        payload: {
          data: payload,
          key: 'loansVariance',
        },
      })
      onSuccess()
    },
    null,
    false,
  )
}

export function getOptionsBeta(onSuccess = emptyFunction) {
  return get(baseUrl, 'options/beta', payload => {
    bufferedQueue('betaData', {
      type: Type.SET_DATA,
      payload: {
        data: payload,
        key: 'betaData',
      },
    })
    onSuccess()
  })
}

export function getOptionsVolatility(onSuccess = emptyFunction) {
  return get(baseUrl, 'options/vol', payload => {
    bufferedQueue('volatilityData', {
      type: Type.SET_DATA,
      payload: {
        data: payload,
        key: 'volatilityData',
      },
    })
    onSuccess()
  })
}

export function getOptionsStrikeRollup(onSuccess = emptyFunction) {
  return get(baseUrl, 'options/strikerollup', payload => {
    bufferedQueue('strikeRollupData', {
      type: Type.SET_DATA,
      payload: {
        data: payload,
        key: 'strikeRollupData',
      },
    })
    onSuccess()
  })
}

export function getOptionsExpiryRollup(onSuccess = emptyFunction) {
  return get(baseUrl, 'options/expiryrollup', payload => {
    bufferedQueue('expiryRollupData', {
      type: Type.SET_DATA,
      payload: {
        data: payload,
        key: 'expiryRollupData',
      },
    })
    onSuccess()
  })
}
