import { Dispatch, AnyAction } from 'redux'
import emptyFunction from 'utils/emptyFunction'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'

let storeDispatch: Dispatch

export function dispatch<T extends AnyAction>(action: T) {
  if (!storeDispatch) {
    fakeQueue.push(action)
  } else {
    setTimeout(() => {
      storeDispatch(action)
    }, 0)
  }
  return action
}

const onActionsProcessedCallbacks: VoidFunction[] = []

export function onActionsProcessed(callback: VoidFunction) {
  onActionsProcessedCallbacks.push(callback)
}

const fakeQueue: AnyAction[] = []
export function __setStoreDispatch(realDispatch) {
  storeDispatch = realDispatch
  while (fakeQueue.length) {
    dispatch(fakeQueue.shift())
  }
}

export function getKeyedDispatchQueue() {
  let queue: Record<PropertyKey, AnyAction> = {}
  let scheduled = false
  return (
    id: PropertyKey,
    action: AnyAction,
    onSuccess: VoidFunction = emptyFunction,
  ) => {
    queue[id] = action
    if (!scheduled) {
      scheduled = true
      queryThenMutateDOM(() => {
        while (Object.keys(queue).length) {
          const currentQueue = queue
          queue = {}
          Object.values(currentQueue).forEach(action => {
            dispatch(action)
          })
        }
        onSuccess()
        scheduled = false
      }, null)
    }
  }
}
