import React, { FC, PropsWithChildren } from 'react'
import { useAuth } from 'utils/authOkta'
import { isG1 } from 'utils/envUtils'
import { OktaLoginError, ServiceError } from './SystemError'

const Secure: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated: isOktaAuthenticated, authorizationState } = useAuth()
  const isAuthed = authorizationState === 'authorized'

  if (!isAuthed || !isOktaAuthenticated) {
    if (!isG1()) {
      return null
    }

    switch (authorizationState) {
      case 'unauthorized':
        return <OktaLoginError showReachToSupport />
      case 'error':
        return <ServiceError />
      default:
        return null
    }
  }

  return <>{children}</>
}

export default React.memo(Secure)
