import React, { useCallback } from 'react'
import cx from 'classnames'
import { useContextSelector } from 'use-context-selector'
import { Context } from './Context'

type Props = {
  row?: any
  config?: any
  children: any
  rowId?: any
  contexted?: boolean
  setContextRow?: any
  setHover?: Function
  rowIndex?: any
  columns?: any[]
  index?: number
} & React.HTMLAttributes<HTMLDivElement>

const GridTableRowImpl = (props: Props) => {
  const {
    children,
    row,
    config,
    setHover,
    rowId,
    rowIndex,
    contexted,
    setContextRow,
    className,
    columns,
    index,
    ...htmlProps
  } = props

  const isHovered = useContextSelector(
    Context,
    useCallback(
      context => {
        const { getRowKey, hoveredRow } = context
        return hoveredRow && row && getRowKey(hoveredRow) === getRowKey(row)
      },
      [row],
    ),
  )
  const isContexted = useContextSelector(
    Context,
    useCallback(
      context => {
        const { getRowKey, contextedRow } = context
        return contextedRow && row && getRowKey(contextedRow) === getRowKey(row)
      },
      [row],
    ),
  )

  return (
    <div
      className={cx(className, {
        hovered: isHovered,
        contexted: isContexted,
      })}
      {...htmlProps}
    >
      {children}
    </div>
  )
}

export const GridTableRow = React.memo(GridTableRowImpl)
