import createTypes from 'redux/createTypes'
import { PortfolioMode, PortfolioModel } from 'redux/models/Portfolio'
import { dispatch, getKeyedDispatchQueue } from 'utils/dispatch'

export const Type = createTypes('Portfolio', {
  SET_DATA: 1,
  UPDATE_DATA: 1,
  SET_TABLE_MID_WIDTH: 1,
  SET_FIELD: 1,
  ADD_CHART: 1,
  CHANGE_CHART: 1,
  SET_EOD_PRICES: 1,
  SET_PNL_REPORT_DATA: 1,
  SET_DERIVATIVE_DATA: 1,
  AFTER_SET_EOD_PRICES: 1,
  SET_ANALYSIS_TO_DATE: 1,
  SET_MODE: 1,
})

const bufferedQueue = getKeyedDispatchQueue()

export function updateField<T extends keyof PortfolioModel>(
  field: T,
  value: PortfolioModel[T],
): void {
  dispatch({
    type: Type.SET_FIELD,
    payload: {
      value: value,
      field: field,
    },
  })
}

export function addChart(currency: string): void {
  dispatch({
    type: Type.ADD_CHART,
    payload: {
      symbol: currency + 'USD',
    },
  })
}

export function setEODPrices(prices) {
  bufferedQueue(0, {
    type: Type.SET_EOD_PRICES,
    payload: prices,
  })
}

export function setDerivativeData(strategy, data) {
  bufferedQueue(strategy, {
    type: Type.SET_DERIVATIVE_DATA,
    payload: {
      data,
      strategy,
    },
  })
}

export function setAnalysisToDate(date) {
  bufferedQueue('analysis_date', {
    type: Type.SET_ANALYSIS_TO_DATE,
    payload: { date },
  })
}

export function setMode(mode: PortfolioMode) {
  bufferedQueue('mode', {
    type: Type.SET_MODE,
    payload: { mode },
  })
}
