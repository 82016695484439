import { useRef, useLayoutEffect } from 'react'
import { shallowEqual } from 'utils/shallowEqual'

export const useResizeObserver = (observer, elements) => {
  const prevElements = useRef()
  let els = elements
  if (shallowEqual(prevElements.current, elements)) {
    els = prevElements.current
  } else {
    prevElements.current = elements
  }
  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver
    try {
      resizeObserver = new ResizeObserver(observer)
      els.forEach(element => {
        if (element) {
          resizeObserver.observe(element)
        }
      })
    } catch (e) {
      // do nothing
    }
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
    }
  }, [els, observer])
}
