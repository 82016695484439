import { useLayoutEffect, useRef, MutableRefObject as Ref } from 'react'

export const usePrevious = <T>(value: T): Ref<T | undefined> => {
  const prevRef = useRef<T>()
  const currentRef = useRef<T>()

  prevRef.current = currentRef.current
  currentRef.current = value

  return prevRef
}

export const usePrevious2 = <T>(value: T): Ref<T | undefined> => {
  const prevRef = useRef<T>()

  useLayoutEffect(() => {
    // Isomorphic effect
    prevRef.current = value
  })

  return prevRef
}
