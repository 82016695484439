import React, { useCallback, useMemo, useRef, FC, ReactElement } from 'react'
import deepEqual from 'react-fast-compare'
import { produce } from 'immer'
import memoizee from 'memoizee'
import { ServerSideFilter } from 'components/GridTable/GridTableServerModelHelper'
import { useEffectNow } from 'hooks/useEffectNow'
import { usePrevious } from 'hooks/usePrevious'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { useUpdatingState } from 'hooks/useUpdatingState'
import { emptyObject } from 'utils/emptyObject'

export type FilterConfig = {
  filters: {
    [colId: string]: {
      Klass: FC<{
        filterArgs: any
        setFilter: Function
      }>
      allValues?: any[]
      filterFn: (filterArgs: any, value: any) => boolean
      toServerModel: (filterArgs: any) => ServerSideFilter
      needsFormatted?: boolean
      needsAllValues?: boolean
      bypass?: Function
      getFilterValue?: Function
    }
  }
  activeFilters: {
    [colId: string]: any
  }
}

type Props<T> = {
  id: string
  config: FilterConfig
  onConfigChange: (config: FilterConfig) => void
  rowData: any[]
  columnConfig: import('../useGridTable').BaseColumnConfig<T>[]
  getCellValue: Function
  getCellValueFormatted: Function
}

type Return = {
  getFilterComponent: (colId: string) => ReactElement
  filteredRowData: any[]
}

export function useGridTableFilter<T>({
  id,
  rowData,
  config,
  columnConfig,
  onConfigChange,
  getCellValue,
  getCellValueFormatted,
}: Props<T>): Return {
  const { activeFilters, filters } = config
  const configRef = useUpdatingRef(config)
  const onConfigChangeRef = useUpdatingRef(onConfigChange)
  const activeFiltersRef = useUpdatingRef(activeFilters)

  const onFilterChange = useCallback(
    (colId, filterArgs) => {
      const newConfig = produce(configRef.current, draft => {
        if (filterArgs) {
          draft.activeFilters[colId] = filterArgs
        } else {
          draft.activeFilters[colId] = undefined
        }
      })
      onConfigChangeRef.current(newConfig)
    },
    [id],
  )

  const _valuesForFiltersByCol = useMemo(() => {
    const values = {}
    columnConfig.forEach(config => {
      const id = config.id
      values[id] = filters[id].allValues || []
      if (filters[id].needsAllValues && !filters[id].allValues) {
        rowData.forEach(row => {
          if (config.getFilterValue) {
            values[id].push(
              config.getFilterValue({
                row: row,
                col: config,
                getCellValue,
                getCellValueFormatted,
              }),
            )
          } else if (filters[id].needsFormatted) {
            values[id].push(getCellValueFormatted(config, row))
          } else {
            values[id].push(getCellValue(config, row))
          }
        })
      }
    })
    return values
  }, [columnConfig, rowData, filters, activeFilters])

  const valuesForFiltersRef = useRef(_valuesForFiltersByCol)
  if (!deepEqual(valuesForFiltersRef.current, _valuesForFiltersByCol)) {
    valuesForFiltersRef.current = _valuesForFiltersByCol
  }

  const columnsById = useMemo(() => {
    const cols = {}
    columnConfig.forEach(col => {
      cols[col.id] = col
    })
    return cols
  }, [columnConfig])

  const filteredRowData = useMemo(() => {
    const keys = Object.keys(activeFilters)
    if (!keys.length) {
      return rowData
    }
    const filtered = rowData.filter((row, index) => {
      let filtered = false
      for (const key of keys) {
        const filter = filters[key]
        const bypass = filter.bypass
        if (!bypass || !bypass(row)) {
          if (activeFilters[key]) {
            const args = activeFilters[key]
            const column = columnsById[key]
            let value
            if (column.getFilterValue) {
              value = column.getFilterValue({
                row: row,
                col: column,
                getCellValue,
                getCellValueFormatted,
              })
            } else if (column.needsFormatted) {
              value = getCellValueFormatted(column, row)
            } else {
              value = getCellValue(column, row)
            }
            filtered = column.filter.filterFn(args, value)
            if (filtered) {
              break
            }
          }
        }
      }
      return !filtered
    })
    return filtered
  }, [
    activeFilters,
    filters,
    getCellValueFormatted,
    getCellValue,
    columnsById,
    rowData,
  ])

  const setFilterArgsCBStore = useRef({})
  const setFilterValuesCBStore = useRef({})
  const getSetFilter = useMemo(() => {
    const store = []
    return colId => {
      if (!store[colId]) {
        store[colId] = args => {
          onFilterChange(colId, args)
        }
      }
      return store[colId]
    }
  }, [])

  const getFilterComponent = useCallback(
    memoizee((colId: string) => {
      const config = configRef.current
      if (!config.filters[colId]) {
        return null
      }
      const { Klass } = config.filters[colId]
      if (!Klass) {
        return null
      }
      const initialFilterArgs = activeFiltersRef.current[colId]
      const initialAllValues = valuesForFiltersRef.current[colId]

      return (
        <FilterKlass
          initialAllValues={initialAllValues}
          initialFilterArgs={initialFilterArgs}
          setFilter={getSetFilter(colId)}
          colId={colId}
          Klass={Klass}
          setFilterArgsCBStore={setFilterArgsCBStore}
          setFilterValuesCBStore={setFilterValuesCBStore}
        />
      )
    }),
    [],
  )

  const prevFilters = usePrevious(activeFilters).current || emptyObject
  if (prevFilters !== activeFilters) {
    const allKeys = Array.from(
      new Set([...Object.keys(activeFilters), ...Object.keys(prevFilters)]),
    )
    allKeys.forEach(key => {
      if (setFilterArgsCBStore.current[key] === undefined) {
        return
      }
      const nextFilter = activeFilters[key]
      if (prevFilters[key] === nextFilter) {
        return
      }
      if (nextFilter) {
        setFilterArgsCBStore.current[key](nextFilter)
      } else {
        setFilterArgsCBStore.current[key](null)
      }
    })
  }

  useEffectNow(() => {
    const vals = valuesForFiltersRef.current
    const cbs = setFilterValuesCBStore.current
    Object.keys(vals).forEach(key => {
      if (cbs[key]) {
        cbs[key](vals[key])
      }
    })
  }, [valuesForFiltersRef.current])

  return {
    filteredRowData,
    getFilterComponent,
  }
}

const FilterKlass = React.memo(
  ({
    Klass,
    colId,
    initialAllValues,
    initialFilterArgs,
    setFilter,
    setFilterArgsCBStore,
    setFilterValuesCBStore,
  }: any) => {
    const [filterArgs, setFilterArgs] = useUpdatingState(initialFilterArgs)
    const [allValues, setAllValues] = useUpdatingState(initialAllValues)

    setFilterArgsCBStore.current[colId] = setFilterArgs
    setFilterValuesCBStore.current[colId] = allValues => {
      setAllValues(allValues)
    }
    return (
      <Klass
        allValues={allValues}
        filterArgs={filterArgs}
        setFilter={setFilter}
      />
    )
  },
)
