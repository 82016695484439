import { forkJoin } from 'rxjs'
import emptyFunction from 'utils/emptyFunction'
import { fetchableOnce, create, update, get } from 'utils/http'

const refUrl = process.env.NEXT_PUBLIC_REFERENCE_SERVICE_BASE_URL || ''
const baseUrl = process.env.NEXT_PUBLIC_SETTLEMENT_SERVICE_BASE_URL || ''
const transferUrl = process.env.NEXT_PUBLIC_TRANSFERS_SERVICE_BASE_URL || ''

export function updateTransfer(
  transferPayload,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return update(
    transferUrl,
    `transfers/transferId=${transferPayload.id}`,
    transferPayload,
    onSuccess,
    onError,
  )
}

export function saveTransfer2(
  props,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return create(transferUrl, `transfers/create`, props, onSuccess, onError)
}

export function getTransferByIDWithSettlements(
  transferId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  const data1 = getTransferByID(transferId)
  const data2 = get(baseUrl, `settlement/transfer/${transferId}`).retry()
  forkJoin(data1.ds, data2.ds).subscribe(res => {
    const data = { ...(res[0] as any), ...(res[1] as any) } as any
    onSuccess({
      ...data,
      id: data.id || data.transferId,
      transferIdThirdParty: data.transferIdThirdParty,
      transferId: data.id || data.transferId,
      sourceSystemID: data.sourceSystemId,
      sourceSystemName: data.sourceSystemName,
      tradeDate: data.date,
      fromAcct: data.fromAccountId,
      fromAcctName: data.fromAccountName,
      toAcct: data.toAccountId,
      toAcctName: data.toAccountName,
      securityId: data.transferSecurityId,
      securitySymbol: data.transferSecurity,
      transferConfirmationStatusId:
        data.confirmationStatusId || data.transferConfirmationStatusId,
      confirmationStatusId:
        data.confirmationStatusId || data.transferConfirmationStatusId,
      confirmationStatus: data.confirmationStatus,
      totalAmount: data.funds,
      unallocatedQty: data.unallocatedQty,
      blockFee: data.blockFee,
      serviceFee: data.serviceFee,
      note: data.notes,
      txHash: data.referenceNumber || data.txHash,
      settlements: data.settlements,
      loans: data.loanActivities,
      externalTxId: data.externalTxId,
    })
  }, onError)
}

export function getTransferByID(
  transferId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    transferUrl,
    `transfers/transferId=${transferId}`,
    onSuccess,
    onError,
  ).retry()
}
export const getTransferStatuses = fetchableOnce(
  (onSuccess = emptyFunction, onError = emptyFunction) => {
    return get(transferUrl, `transfers/allconfirmstatus`, onSuccess, onError)
  },
)

export const getTransferEntities = fetchableOnce(
  (onSuccess = emptyFunction, onError = emptyFunction) => {
    return get(refUrl, 'api/entities', onSuccess, onError)
  },
)

export const getTransferTypes = fetchableOnce(
  (onSuccess = emptyFunction, onError = emptyFunction) => {
    return get(transferUrl, `transfers/alltransfertypes`, onSuccess, onError)
  },
)

export const getAddressTypes = fetchableOnce(
  (onSuccess = emptyFunction, onError = emptyFunction) => {
    return get(
      transferUrl,
      `transfers/address/alladdresstypes`,
      onSuccess,
      onError,
      false,
    )
  },
)

type Props = {
  accountId: number
  address: string
  accountAddressTypeId: number
  securityId: number
}
export const saveAddressMapping = (
  props: Props,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) => {
  return create(
    transferUrl,
    `transfers/address/create`,
    {
      addressStatusId: 3, // aproved
      ...props,
    },
    onSuccess,
    onError,
  )
}
