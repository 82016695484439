import React, { ComponentType, memo, useCallback, useRef } from 'react'
import cx from 'classnames'
import { Input, InputProps } from 'semantic-ui-react'
import { usePrevious } from 'hooks/usePrevious'
import { useStateRef } from 'hooks/useStateRef'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import getNumberFromInput from 'utils/getNumberFromInput'
import { formatToNonZeroPrecision } from '../Galaxy/GalaxyUtils'

type Props = Omit<InputProps, 'as'> & {
  as?: ComponentType<InputProps>
  scale?: number
  formatter?: (valueObj: { value: unknown }) => string
  onValueChanged?: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: { value: number; name: string },
  ) => void
}

function isReallyNaN(num) {
  if (num && num.length > 1 && num[num.length - 1] === '.') {
    if (/\..*\./.test(num)) {
      return true
    }
    return isNaN(num.slice(0, num.length - 1))
  }
  return isNaN(num)
}

const GalaxyNumber: React.FC<Props> = props => {
  const { formatter, value, scale, children, ...rest } = props
  delete rest.onValueChanged
  const prevPropsRef = usePrevious<Props>(props)
  const [inEditModeRef, setInEditMode] = useStateRef<boolean>(false)
  const numberValue = getNumberFromInput(value)
  const numberValueRef = useUpdatingRef(numberValue)
  const prevNumberValueRef = usePrevious(numberValue)
  const [editValueRef, setEditValue] = useStateRef(
    numberValue === 0 ? '' : numberValue,
  )
  if (
    !isReallyNaN(editValueRef.current) &&
    numberValueRef.current !== getNumberFromInput(editValueRef.current)
  ) {
    editValueRef.current = numberValue
  }

  const formattedValueRef = useRef('')
  if (
    prevNumberValueRef.current !== null &&
    numberValueRef.current !== prevNumberValueRef.current
  ) {
    let newFormattedValue = value
    if (value == null) {
      newFormattedValue = editValueRef.current
    }
    if (newFormattedValue && !isReallyNaN(newFormattedValue)) {
      if (newFormattedValue != null && newFormattedValue !== '') {
        if (formatter) {
          newFormattedValue = formatter({ value })
        } else {
          newFormattedValue = formatToNonZeroPrecision(
            parseFloat(newFormattedValue),
            scale || 8,
          )
        }
      } else {
        newFormattedValue = 0
      }
    }
    formattedValueRef.current = newFormattedValue
  }

  const onChange = useCallback<InputProps['onChange']>(
    ev => {
      const prevProps = prevPropsRef.current
      setEditValue(ev.target.value)

      if (prevProps?.onValueChanged) {
        const raw = ev.target.value
        const number = getNumberFromInput(raw)
        const floated = parseFloat('' + number)
        const value = isReallyNaN(floated) ? 0 : floated
        prevProps.onValueChanged(ev, {
          value,
          name: prevProps.name,
        })
      }
    },
    [prevPropsRef, setEditValue],
  )

  const toggleInEditMode = useCallback(
    (ev: React.SyntheticEvent) => {
      ev.stopPropagation()
      setInEditMode(!inEditModeRef.current)
    },
    [setInEditMode, inEditModeRef],
  )

  if (inEditModeRef.current && !props.disabled) {
    return (
      <Input
        {...rest}
        key="number"
        className={cx('galaxyNumbers', props.className)}
        input={input}
        autoFocus
        onChange={onChange}
        onBlur={toggleInEditMode}
        value={editValueRef.current}
      >
        {children}
      </Input>
    )
  }
  return (
    <Input
      {...rest}
      input={input}
      key="text"
      type="text"
      control={props.control}
      label={props.label}
      style={props.style}
      onFocus={toggleInEditMode}
      value={formattedValueRef.current}
    >
      {children}
    </Input>
  )
}

export const input = <TProps,>(Type: ComponentType<TProps>, props: TProps) => {
  return <Type {...props} data-lpignore="true" />
}

export default memo(GalaxyNumber)
