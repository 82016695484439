/**
 * README:
 * https://galaxydig.atlassian.net/wiki/spaces/TECH/pages/429260824/Gatekeeper
 */
import { isBrowser } from 'utils/isBrowser'

const defaultFeatures: GateKeeperFeatures = {}

let gateKeeperFeatures: GateKeeperFeatures = {}
if (isBrowser) {
  gateKeeperFeatures = self.Features || defaultFeatures
}

function initialize(featureStore: GateKeeperFeatures, search: string): void {
  if (!search) {
    return
  }

  const featurePattern = /gk_(on|off)=([^&]*)/g
  const featureState: Record<GateKeeperMode, boolean> = {
    gk_off: false,
    gk_on: true,
  }

  const features = search.match(featurePattern)

  if (!features) {
    return
  }

  features
    .map(
      feature => feature.split('=') as [GateKeeperMode, GateKeeperFeatureName],
    )
    .sort(([modeA], [modeB]) =>
      modeA === modeB ? 0 : modeA === 'gk_on' ? -1 : 1,
    )
    .reduce((acc, [mode, name]) => {
      acc[name] = featureState[mode]
      return acc
    }, featureStore)
}

try {
  if (typeof self !== 'undefined') {
    initialize(gateKeeperFeatures, self.location?.search)
  }
} catch (error) {
  console.error(new Error('[Handled error]', { cause: error }))
}

export function get(featureName: GateKeeperFeatureName): boolean
export function get(...featureNames: GateKeeperFeatureName[]): boolean[]
export function get(
  ...featureNames: GateKeeperFeatureName[]
): boolean | boolean[] {
  const gates = featureNames.map(f => !!gateKeeperFeatures[f])
  return gates.length === 1 ? gates[0] : gates
}

type GateKeeperMode = 'gk_on' | 'gk_off'
export type GateKeeperFeatures = Partial<Record<GateKeeperFeatureName, boolean>>
export type GateKeeperFeatureName =
  | 'auto_settlement'
  | 'g1_qa_auto_signout_timeout'
  | 'g1_show_changelog'
  | 'enable_longer_maintenance_window'
