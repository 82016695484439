import React, { FunctionComponent } from 'react'
import { IconUrlMap } from './icons'
import * as Styled from './styled'
import { IconName, IconSize } from './types'

const sizeMap: Record<IconSize, number> = {
  small: 16,
  medium: 24,
  large: 32,
}

export const Icon: FunctionComponent<Props> = ({
  name,
  size = 'medium',
  color,
}) => {
  return (
    <Styled.Icon svg={IconUrlMap[name]} size={sizeMap[size]} color={color} />
  )
}

type Props = {
  name: IconName
  size?: IconSize
  color?: string
}
