import { useCallback } from 'react'
import memoize from 'memoizee'
import memoizeWeak from 'memoizee/weak'
import { emptyObject } from 'utils/emptyObject'

const _useCurriedCallback = (memo, callback, deps: any[], options?: any) => {
  return useCallback(
    memo(
      (...curryArgs) => {
        return (...args) => {
          callback(...curryArgs, ...args)
        }
      },
      { length: false, ...(options || emptyObject) },
    ),
    deps,
  )
}

export const useCurriedCallback = (callback, deps: any[], options?: any) => {
  return _useCurriedCallback(memoize, callback, deps, options)
}

export const useWeakMapCurriedCallback = (
  callback,
  deps: any[],
  options?: any,
) => {
  return _useCurriedCallback(memoizeWeak, callback, deps, options)
}
