import { RootState } from 'redux/reducers/state'

type ReturnType<T> = Record<keyof T, string>
export default <T extends Record<string, string | number>>(
  base: keyof RootState,
  types: T,
): ReturnType<T> => {
  const clone = {}
  Object.keys(types).forEach(key => {
    clone[key] = `${base}:${key}`
  })

  return clone as ReturnType<T>
}
