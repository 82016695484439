import { QueryKey, useQuery, UseQueryOptions } from 'react-query'
import errorToast from 'utils/errorToast'
import { getOktaToken } from 'utils/getOktaToken'

const defaultStaleTime = 60000 // one minute
const defaultRetries = 2

const useGetQuery = <TData = unknown, TError = unknown>(
  url: string,
  queryKey: QueryKey,
  withAuth = false,
  options: Omit<UseQueryOptions<TData, TError>, 'queryKey' | 'queryFn'> = {},
) => {
  return useQuery<TData, TError>(
    queryKey,
    async () => {
      const res = await fetch(
        url,
        withAuth
          ? {
              headers: {
                Authorization: `Bearer ${await getOktaToken()}`,
              },
            }
          : undefined,
      )
      if (res.ok) {
        return res.json()
      } else {
        const { error } = await res.json()
        throw new Error(error)
      }
    },
    {
      onError: () => {
        errorToast()
      },
      retry: defaultRetries,
      staleTime: defaultStaleTime,
      ...options,
    },
  )
}

export default useGetQuery
