import React, { useCallback, useLayoutEffect, useMemo, useRef } from 'react'
import { dateFormatter } from 'components/Galaxy/GalaxyGridHelper'
import {
  dateFilter,
  dateSorter,
  numberFilter,
  numberSorter,
  setFilter,
  SORTDIR,
  stringSorter,
  useGridTable,
} from 'components/GridTable'
import Modal from 'components/Modal'
import { getOrders } from 'data'
import { useBoolFlag } from 'hooks/useBoolFlag'
import { useInterval } from 'hooks/useInterval'
import { useSelectorPerformant } from 'hooks/useSelectorPerformant'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { RootState } from 'redux/reducers/state'
import { emptyArray } from 'utils/emptyArray'
import { formatNumber } from 'utils/formatNumber'
import { getState } from 'utils/getState'
import { requestAnimationFrameEnd } from 'utils/requestAnimationFrame'

export const TradeCell = React.memo((props: any) => {
  return (
    // <Provider store={store()}>
    <TradeCellImpl {...props} />
    // </Provider>
  )
})
const TradeCellImpl = React.memo((props: any) => {
  const [showOrders, setShowOrders, setHideOrders] = useBoolFlag(false)
  let dialog
  if (showOrders) {
    dialog = (
      <ContextMenu
        show
        setShow={setHideOrders}
        type={props.colDef.field}
        currency={props.data.currency}
        typeName={props.colDef.headerName}
      />
    )
  }
  const onClick = useCallback(() => {
    if (
      ['cpFilled', 'exchangeFilled', 'exchangeOpen'].includes(
        props.colDef.field,
      )
    ) {
      setShowOrders()
    }
  }, [])
  return (
    <div onDoubleClick={onClick as any}>
      {props.valueFormatted}
      {dialog}
    </div>
  )
})
type ContextMenuProps = {
  show: boolean
  setShow: Function
  type: string
  typeName: string
  currency: string
}
export const ContextMenu = React.memo((props: ContextMenuProps) => {
  const setShowRef = useUpdatingRef(props.setShow)
  const rootRef = useRef<HTMLDivElement>(null)

  const [, , hideIsFetching] = useBoolFlag(true)
  const typeRef = useUpdatingRef<any>(props.type)
  const currencyRef = useUpdatingRef(props.currency)
  const fetchData = useCallback(() => {
    return getOrders(typeRef.current, currencyRef.current, hideIsFetching)
      .cancel
  }, [currencyRef, hideIsFetching, typeRef])
  useInterval(fetchData, 30000)

  const { currency, type, typeName } = props
  const orders = useSelectorPerformant<
    RootState,
    RootState['LiveRisk']['tradeOrders']['']['']
  >(rootState => {
    if (!rootState.LiveRisk.tradeOrders[currency]) {
      return emptyArray
    }
    return (
      rootState.LiveRisk.tradeOrders[currency][typeRef.current] || emptyArray
    )
  })

  const columns = useMemo(() => getColDefs(type === 'exchangeOpen'), [type])

  let exportName = 'CP Fills'
  if (type === 'exchangeFilled') {
    exportName = 'Exchange Fills'
  } else if (type === 'exchangeOpen') {
    exportName = 'Exchange Open'
  }
  exportName = `Trade Report ${exportName}`

  const { table, api } = useGridTable<any>({
    columns,
    rowData: orders,
    getRowKey: useCallback(row => row.s_order_id_external, []),
    id: exportName,
    isLoading: orders === emptyArray,
  })

  const listener = useCallback(
    event => {
      const root = rootRef.current
      if (root && !root.contains(event.target)) {
        setShowRef.current(false)
      }
    },
    [setShowRef],
  )

  useLayoutEffect(() => {
    if (props.show) {
      requestAnimationFrameEnd(() => {
        document.body.addEventListener('mousedown', listener)
      })
    } else {
      document.body.removeEventListener('mousedown', listener)
    }
    return () => {
      document.body.removeEventListener('mousedown', listener)
    }
  }, [props.show, listener])

  return (
    <Modal
      header={`${currency}: ${typeName} (${orders.length})`}
      onClose={props.setShow as any}
      open
      actions={[]}
      className="live-risk-trade-report-dialog"
      size="fullscreen"
    >
      {table}
    </Modal>
  )
})
const vf = ({ value }) => formatNumber(getState().LiveRisk.decimalPlaces, value)
const getColDefs = isLeaves => {
  const cols = [
    {
      headerName: 'Trade Date',
      field: 'dt_trade_date',
      valueFormatter: dateFormatter,
      filter: dateFilter,
      sortAsc: dateSorter,
      getExportValue: dateFormatter,
      sortDir: SORTDIR.DESC,
    },
    {
      headerName: 'CP Name',
      field: 's_name',
      filter: setFilter,
      sortAsc: stringSorter,
    },
    {
      headerName: 'Order Side',
      field: 'id_order_side',
      valueFormatter: ({ value }) => (value === 1 ? 'Buy' : 'Sell'),
      filter: setFilter,
      sortAsc: stringSorter,
    },
    {
      headerName: 'Ccy Pair',
      field: 's_symbol',
      filter: setFilter,
      sortAsc: stringSorter,
    },
    {
      headerName: 'Quantity Filled',
      field: 'dec_quantity_filled',
      valueFormatter: vf,
      filter: numberFilter,
      sortAsc: numberSorter,
    },
    {
      headerName: 'Price',
      field: 'dec_limit_price',
      valueFormatter: vf,
      filter: numberFilter,
      sortAsc: numberSorter,
    },
    {
      headerName: 'Notional',
      field: 'dec_notional_in_trade_currency',
      valueFormatter: vf,
      filter: numberFilter,
      sortAsc: numberSorter,
    },
  ]
  if (isLeaves) {
    cols.splice(4, 1, {
      // Replace Quantity Filled column
      headerName: 'Quantity Leaves',
      field: 'dec_quantity_leaves',
      valueFormatter: vf,
      filter: numberFilter,
      sortAsc: numberSorter,
    })
  }
  return cols
}
