import { useEffect, useState, RefCallback, useCallback } from 'react'

export type UseHasBeenDisplayedHook = [boolean, RefCallback<Element>]

export const useHasBeenDisplayed = (): UseHasBeenDisplayedHook => {
  const [hasBeenDisplayed, setHasBeenDisplayed] = useState<boolean>(false)
  const [observer] = useState<IntersectionObserver>(
    () =>
      new IntersectionObserver(entries => {
        if (entries[0].intersectionRatio !== 0) {
          setHasBeenDisplayed(true)
        }
      }),
  )

  useEffect(
    () => () => {
      observer.disconnect()
    },
    [observer],
  )

  const hasBeenDisplayedRef = useCallback<RefCallback<HTMLElement>>(
    element => {
      if (element) {
        observer.observe(element)
      }
    },
    [observer],
  )

  return [hasBeenDisplayed, hasBeenDisplayedRef]
}
