import React, { useEffect, useRef, useLayoutEffect, useMemo, FC } from 'react'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import {
  Button,
  Container,
  Dropdown,
  Image,
  Menu,
  Popup,
} from 'semantic-ui-react'
import Link from 'components/Link'
import PnLReportDialog from 'components/PnLReportDialog'
import PositionReportDialog from 'components/PositionReportDialog'
import useAuthUser from 'hooks/useAuthUser'
import { useBoolFlag } from 'hooks/useBoolFlag'
import { usePrevious } from 'hooks/usePrevious'
import { useSelectorPerformant } from 'hooks/useSelectorPerformant'
import { Type as UserAction } from 'redux/actions/user'
import { Strategy } from 'redux/models'
import { RootState } from 'redux/reducers/state'
import { login, logout } from 'utils/auth'
import CSSCore from 'utils/CSSCore'
import { dispatch } from 'utils/dispatch'
import { emptyArray } from 'utils/emptyArray'
import { getEnv, isProd } from 'utils/envUtils'
import getApplicationsUrl from 'utils/getApplicationsUrl'
import galaxyLogo from './ClientGalaxyNavigation/clientNavAssets/galaxyLogo.svg'

import Styles from './GalaxyNavigation.module.scss'

const getStrategyItem = (
  selectedStrategy: Strategy,
  s: Strategy,
  i: number,
) => (
  <Dropdown.Item
    key={i}
    onClick={() => {
      dispatch({
        type: UserAction.SELECTED_STRATEGY_CHANGED,
        payload: { selectedStrategy: s },
      })
    }}
    className={cx({ active: selectedStrategy?.id === s.id })}
  >
    {s.name}
  </Dropdown.Item>
)

const getSubStrategyItem = (
  selectedStrategy: Strategy | undefined,
  sub: Strategy,
  subIndex: number,
) => (
  <Dropdown.Item
    key={`sub-${sub}${subIndex}`}
    onClick={() => {
      dispatch({
        type: UserAction.SELECTED_STRATEGY_CHANGED,
        payload: { selectedStrategy: sub },
      })
    }}
    className={cx(Styles.nestedMenuItem, 'tree-menu', {
      active: selectedStrategy?.id === sub.id,
    })}
  >
    {sub.name}
  </Dropdown.Item>
)

const GalaxyNavigation: FC = () => {
  const activeItem = useRouter().pathname.toLowerCase()
  const user = useSelectorPerformant<RootState, string>(rootState => {
    const { data } = rootState.GalaxyUser
    return data?.firstName ? [data.firstName, data.lastName].join(' ') : null
  })
  const prevActiveItem = useRef<string | null>(null)
  const commitId = process.env.COMMIT_ID
  useEffect(() => {
    if (user) {
      prevActiveItem.current = activeItem
    }
  }, [user, activeItem])
  const previousActiveItem = usePrevious(activeItem).current
  const { strategies = emptyArray, selectedStrategy } = useAuthUser('internal')
  const [showPnlReport, setShowPnlReport, setHidePnlReport] = useBoolFlag(false)
  const [showPositionReport, setShowPositionReport, setHidePositionReport] =
    useBoolFlag(false)
  const strategiesJsx = useMemo(() => {
    return strategies.map((s, i) => {
      if (isEmpty(s?.applications)) return null

      const subStrategiesItems =
        s.subStrategies?.map((sub, subIndex) =>
          getSubStrategyItem(selectedStrategy, sub, subIndex),
        ) ?? []

      return (
        <React.Fragment key={`strategy-${s.id}-${i}`}>
          {getStrategyItem(selectedStrategy, s, i)}
          {subStrategiesItems}
        </React.Fragment>
      )
    })
  }, [strategies, selectedStrategy])

  const menuItemsJsx = useMemo(() => {
    const applicationsWithUrl = getApplicationsUrl(selectedStrategy)
    return applicationsWithUrl.map((s, i) => {
      let active = activeItem === s.url
      const isExternalURL = s.url.startsWith('http')

      if (activeItem.includes('settlements') && s.url.includes('settlements')) {
        active = true
      } else if (isExternalURL) {
        active = false
      }

      return (
        <Menu.Item
          key={`nav-${i}:${selectedStrategy.id}`}
          as={isExternalURL ? undefined : Link}
          href={s.url}
          active={active}
        >
          {s.name}
        </Menu.Item>
      )
    })
  }, [selectedStrategy, activeItem])

  const usersAndTradeSearch = useMemo(() => {
    return [
      ['Counterparties & Users', '/users'],
      ['Deposit Addresses', '/deposit_address'],
      ['Fireblocks Networks', '/fireblocks_networks'],
      ['Finance', '/finance'],
      ['Reporting', '/reporting'],
    ].map(link => {
      if (!link) return null
      const [name, url] = link
      const active = activeItem === url
      return (
        <Menu.Item key={`nav-${url}`} as={Link} href={url} active={active}>
          {name}
        </Menu.Item>
      )
    })
  }, [activeItem])

  if (activeItem !== previousActiveItem) {
    if (previousActiveItem) {
      const prevClass = `_${
        previousActiveItem.replace(/\d/g, '').split('/')[1]
      }`
      CSSCore.removeClass(document.body, prevClass)
    }
    const nextClass = `_${activeItem.replace(/\d/g, '').split('/')[1]}`
    CSSCore.addClass(document.body, nextClass)
  }

  const loggedIntoOkta = useSelectorPerformant<RootState, boolean>(
    rootState => {
      const { data } = rootState.OktaUser
      return Boolean(data?.accessToken)
    },
  )

  useLayoutEffect(() => {
    CSSCore.addClass(document.body, 'internal-portal')
  }, [])

  const isG1Voice = selectedStrategy?.name === 'G1_VOICE'

  return (
    <div className={Styles['galaxy-nav-menu']}>
      <Menu
        inverted
        className={cx({
          [Styles.navBgImage]: true,
          [Styles.g1Voice]: isG1Voice,
        })}
      >
        <Container>
          <Menu.Item header>
            <Image size="small" src={galaxyLogo} className={Styles.logo} />
            <div>
              <Popup
                content={`${process.env.STAGING_NAME} ${
                  commitId && commitId !== 'undefined' ? commitId : ''
                }`}
                position="bottom center"
                offset={[-50, 0]}
                disabled={isProd()}
                trigger={
                  <Button
                    animated="vertical"
                    color="orange"
                    size="tiny"
                    compact
                    className={Styles.envBtn}
                  >
                    <Button.Content visible size="tiny">
                      <p>{getEnv()}</p>
                    </Button.Content>
                    <Button.Content hidden className={Styles.versionText}>
                      {process.env.NEXT_PUBLIC_VERSION}
                    </Button.Content>
                  </Button>
                }
              />
            </div>
          </Menu.Item>
          <Container className={Styles.menus}>
            <Menu.Menu position="left">{menuItemsJsx}</Menu.Menu>
            <Menu.Menu position="right">
              <Dropdown item text="Settings" className={Styles.dropdownWrapper}>
                <Dropdown.Menu>{usersAndTradeSearch}</Dropdown.Menu>
              </Dropdown>
              <Dropdown
                item
                className={Styles.dropdownWrapper}
                text={selectedStrategy?.name || 'Loading strategies...'}
              >
                <Dropdown.Menu className={Styles.dropdownMenu}>
                  {strategiesJsx}
                </Dropdown.Menu>
              </Dropdown>
              {user && <Menu.Item>{user}</Menu.Item>}
              <Menu.Item
                name="logout"
                onClick={loggedIntoOkta ? logout : login}
              >
                Log {loggedIntoOkta ? 'out' : 'in'}
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Container>
      </Menu>
      {showPnlReport ? (
        <PnLReportDialog onClose={setHidePnlReport} open={showPnlReport} />
      ) : null}
      {showPositionReport ? (
        <PositionReportDialog
          onClose={setHidePositionReport}
          open={showPositionReport}
        />
      ) : null}
    </div>
  )
}

export default React.memo(GalaxyNavigation)
