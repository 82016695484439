import { sortBy } from 'lodash'
import { Strategy } from 'redux/models'
import { ApplicationWithUrl } from 'redux/models/Application'

type RouteConfig = {
  url: string
  name?: string
  prefetch?: false
}
const appRouteConfig: Record<number, RouteConfig> = {
  2: { url: '/exposure' },
  4: { url: '/trades/daily' },
  5: { url: '/otc' },
  6: { url: '/trading', name: 'Trading' },
  7: { url: '/trades/historical' },
  8: { url: '/settlements' },
  9: { url: process.env.NEXT_PUBLIC_EXTERNAL_LENDING_APP_URL },
  10: { url: '/portfolio' },
  13: { url: '/transfers' },
  14: { url: '/portfolio-risk' },
  18: { url: '/gotc-monitor' },
  19: { url: '/derivative-pricing' },
  21: { url: '/single-ccy-etf' },
  22: { url: '/derivative-blotter' },
  23: { url: '/derivative-rfq' },
  25: { url: '/derivative-settlements' },
  26: { url: '/tims-transfer-blotter' },
  27: { url: '/derivative-pricer-ui' },
  29: { url: '/lending-settlements' },
}

export default (strategy?: Strategy): ApplicationWithUrl[] => {
  if (!strategy?.applications) {
    return []
  }

  const applicationsWithUrl: ApplicationWithUrl[] = strategy.applications
    .filter(app => Boolean(appRouteConfig[app.id]))
    .map(app => ({
      ...app,
      prefetch: true,
      ...appRouteConfig[app.id],
    }))

  const sortedApplicationsWithUrl: ApplicationWithUrl[] = sortBy(
    applicationsWithUrl,
    [o => o.sequence],
  )

  return sortedApplicationsWithUrl
}
