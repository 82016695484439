export const uniqueID = (() => {
  let counter = 0
  return () => 'uuid_' + counter++
})()

export const uniqueNamespace = () => {
  const keys = {}
  return (key: string) => {
    if (keys[key]) {
      keys[key] += 1
    } else {
      keys[key] = 1
    }
    return key + '_' + keys[key]
  }
}

export const uniqueIDFromObject = (() => {
  const idMap = new WeakMap()
  return (obj: object) => {
    if (!idMap.has(obj)) {
      idMap.set(obj, uniqueID())
    }
    return idMap.get(obj)
  }
})()
