export enum Type {
  SET_SHOCK_SETTINGS = 'portfolfio-risk-set-shock-settings',
  SET_CONTRACT_DATA = 'portfolio-risk-set-contract-data',
  CLEAR_CONTRACT_DATA = 'portfolio-risk-clear-contract-data',
  TOGGLE_CONTRACT = 'portfolio-risk-toggle-contract',

  SET_VOL_SURFACE = 'portfolio-risk-set-volatility-surface',

  TOGGLE_LOAN_AGG = 'portfolio-risk-toggle-loan-agg',
  TOGGLE_ALL_LOAN_AGG = 'portfolio-risk-toggle-all-loan-agg',

  SET_DATA = 'portfolio-risk-set-data',
  SET_SETTINGS = 'portfolio-risk-settings',
}
