import useGetQuery from 'data/hooks/useGetQuery'

type Account = {
  accountId: number
  accountName: string
}

type TIMSAccountResponse = {
  userAccounts: Account[]
}

export const useTIMSAccounts = () => {
  return useGetQuery<TIMSAccountResponse, Error>(
    `${process.env.NEXT_PUBLIC_TIMS_URL}/accounts`,
    ['tims-accounts'],
    true,
  )
}
