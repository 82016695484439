import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Button, Icon, Input, Placeholder } from 'semantic-ui-react'
import { editSettlementNote, getAccountInfo } from 'data'
import useAuthUser from 'hooks/useAuthUser'
import { useBoolFlag } from 'hooks/useBoolFlag'
import Styles from './SettlementNote.module.scss'

const SettlementNote = ({ note = '', accountId }) => {
  const [isEditingSettlementNote, setIsEditingSettlementNote] =
    useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(note)
  const [isSaving, setIsSaving, resetIsSaving] = useBoolFlag(false)

  const { isAdmin } = useAuthUser('internal')

  useEffect(() => {
    setInputValue(note)
  }, [note])

  const handleSettlementNoteEdit = () => {
    setIsEditingSettlementNote(true)
  }
  const handleSettlementNoteChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleSave = () => {
    if (!accountId) return

    setIsSaving()
    editSettlementNote(
      {
        accountId,
        settlementNote: inputValue,
      },
      () => {
        toast.success('Settlement note saved.')
        getAccountInfo(accountId, resetIsSaving, resetIsSaving)
      },
      (e: Error) => {
        toast.error('Error saving settlement note. ' + e?.message)
        resetIsSaving()
      },
    )
    setIsEditingSettlementNote(false)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && isEditingSettlementNote) {
      handleSave()
    }
  }

  if (isSaving)
    return (
      <div className={Styles.skeletonContainer}>
        <Placeholder>
          <Placeholder.Header>
            <Placeholder.Line />
          </Placeholder.Header>
        </Placeholder>
      </div>
    )

  return (
    <div>
      {!isEditingSettlementNote && inputValue && (
        <div className={Styles.settlementNoteContainer}>
          <p>
            {`Note: ${note}`}
            {isAdmin && (
              <span
                className={Styles.editIcon}
                onClick={handleSettlementNoteEdit}
              >
                <Icon name="edit outline" />
              </span>
            )}
          </p>
        </div>
      )}
      {!isEditingSettlementNote && !inputValue && isAdmin && (
        <Button size="small" onClick={handleSettlementNoteEdit}>
          Add Note
        </Button>
      )}
      {isEditingSettlementNote && (
        <div className={Styles.editNoteContainer}>
          <Input
            size="small"
            placeholder="Add note for settlements with this CP account..."
            onChange={handleSettlementNoteChange}
            value={inputValue}
            className={Styles.noteInput}
            onKeyDown={handleKeyDown}
          />
          <Button size="small" type="submit" primary onClick={handleSave}>
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

export default SettlementNote
