import { createContext, useContextSelector } from 'use-context-selector'

const DEFAULT_STATE: {
  hoveredRow?: any
  selectedCell?: any
  contextedRow?: any
  getRowKey?: Function
} = {
  hoveredRow: null,
  selectedCell: null,
  contextedRow: null,
  getRowKey: () => 0,
}
export const Context = createContext(DEFAULT_STATE)
