import { toast } from 'react-toastify'
import { Type } from 'redux/actions/portfolio'
import handleActions from 'redux/handleActions'
import { PortfolioMode, PortfolioModel } from 'redux/models'
import { registerSaver } from 'redux/store/utils'
import { dispatch } from 'utils/dispatch'
import { uniqueID } from 'utils/uniqueID'
import { REDUX_INITIAL_SET } from '../store/localStorage'

const initialState: PortfolioModel = {
  data: {},
  pnlReportDialogData: [],
  derivativeData: {},
  excludeOTC: false,
  hideBalances: true,
  hideBalancesValue: 10000,
  asssetFilter: '',
  activeTradingViewCharts: [{ symbol: 'BITSTAMP:BTCUSD', id: '__1' }],
  eodPrices: {},
  selectedPortfolio: 'Totals',
  includeNonCrypto: false,
  analysisToDate: (() => {
    const date = new Date()
    date.setDate(new Date().getDate() - 1)
    return date
  })(),
  mode: PortfolioMode.Portfolio,
  version: 2,
}

registerSaver(state => {
  return {
    Portfolio: {
      excludeOTC: state.Portfolio.excludeOTC,
      hideBalances: state.Portfolio.hideBalances,
      hideBalancesValue: state.Portfolio.hideBalancesValue,
      activeTradingViewCharts: state.Portfolio.activeTradingViewCharts,
      version: state.Portfolio.version,
      selectedPortfolio: state.Portfolio.selectedPortfolio,
    },
  }
})

export const PortfolioReducer = handleActions<PortfolioModel, any>(
  {
    [REDUX_INITIAL_SET]: (draft, payload) => {
      if (payload.Portfolio) {
        Object.assign(draft, payload.Portfolio)
        if (!payload.Portfolio.version || payload.Portfolio.version < 2) {
          draft.activeTradingViewCharts = (
            payload.Portfolio.activeTradingViewCharts || []
          ).map(chart => ({
            symbol: chart,
            id: uniqueID() + ':' + Math.random(),
          }))
        }
      }
    },
    [Type.SET_DATA]: (draft, payload) => {
      const { data, id } = payload
      draft.data[id] = data
    },
    [Type.UPDATE_DATA]: (draft, payload) => {
      const data = payload
      data.forEach((d: any) => {
        if (!d) {
          return
        }
        d.positions &&
          d.positions.forEach(ed => {
            if (ed.symbol) d[ed.symbol] = ed.qty
          })
        if (!d.strategyId) {
          return
        }
        // @ts-ignore
        draft.data[d.strategyId] = draft.data[d.strategyId] || {
          exposures: {},
          pnl: {},
          pnlPrevDay: {},
          pnlAnalsysis: {},
          pnlDaily: {},
        }
        draft.data[d.strategyId].exposures[d.securityId] = d
      }, null)
    },
    [Type.SET_FIELD]: (draft, payload) => {
      draft[payload.field] = payload.value
    },
    [Type.ADD_CHART]: (draft, payload) => {
      draft.activeTradingViewCharts.unshift({
        symbol: payload.symbol || 'BTCUSD',
        id: uniqueID() + '.' + Math.random(),
      })
      toast.success('Chart Added')
    },
    [Type.CHANGE_CHART]: (draft, payload) => {
      const d = draft.activeTradingViewCharts[payload.index]
      draft.activeTradingViewCharts[payload.index] = {
        id: d.id,
        symbol: payload.symbol,
      }
    },
    [Type.SET_EOD_PRICES]: (draft, payload) => {
      payload.forEach(securityInfo => {
        draft.eodPrices[securityInfo.symbol] = securityInfo
      })
      dispatch({ type: Type.AFTER_SET_EOD_PRICES, payload: {} })
    },
    [Type.SET_PNL_REPORT_DATA]: (draft, payload) => {
      draft.pnlReportDialogData = payload.data
    },
    [Type.SET_DERIVATIVE_DATA]: (draft, payload) => {
      const { strategy, data } = payload
      draft.derivativeData[strategy] = data
    },
    [Type.SET_MODE]: (draft, payload) => {
      draft.mode = payload.mode
    },
    [Type.SET_ANALYSIS_TO_DATE]: (draft, payload) => {
      draft.analysisToDate = payload.date
    },
  },
  initialState,
)
