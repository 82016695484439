import { useCallback, useReducer, useRef, MutableRefObject } from 'react'
import { useListener, PASSIVE } from 'hooks/useListener'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import CSSCore from 'utils/CSSCore'
import { getBoundingClientRect } from 'utils/getBoundingClientRect'
import {
  queryThenMutateDOM,
  queryThenMutateDOMXFramesFromNow,
} from 'utils/queryThenMutateDOM'

type Config = {
  dropImmediatelyOnHover?: false
  cloneCssClass?: string
  indicatorCssClass?: string
  makeClone?: (arg: { node: HTMLElement; id: string }) => HTMLElement
}

export type OnDropCallback = (
  draggingId: string,
  targetId: string,
  placement: Dir,
) => void

export type IDraggableController = DraggableController

export const useDraggableController = (
  onDrop: OnDropCallback,
  config: Config = {},
) => {
  const [, forceRender] = useReducer(s => s + 1, 0)
  const onDropRef = useUpdatingRef(onDrop)
  const onDropCallback = useCallback(
    (a, b, c) => {
      onDropRef.current(a, b, c)
    },
    [onDropRef],
  )
  const controller = useRef(
    new DraggableController(forceRender, onDropCallback, config),
  ).current

  const m = useRef(controller.measureRects.bind(controller))
  useListener(document.body, 'scroll', m.current, PASSIVE)
  useListener(document.body, 'resize', m.current, PASSIVE)
  return controller
}

type Dir = 'BEFORE' | 'AFTER'

class DraggableController {
  forceRender: VoidFunction
  onDrop: OnDropCallback
  dragging?: string | null
  draggedOver?: string | null
  _setNotDragging?: VoidFunction | null
  lastXref: { current: number } = { current: 0 }
  lastYref: { current: number } = { current: 0 }
  _nodes: { [id: string]: MutableRefObject<HTMLDivElement> } = {}
  _rects: { [id: string]: ClientRect } = {}
  indicator = document.createElement('div')
  _closest?: {
    closestID: string
    closestPos: { x: number; y: number; height: number; width: number }
    closestDir: Dir
  }
  _config: Config
  makeClone?: Config['makeClone']

  constructor(
    forceRender: VoidFunction,
    onDrop: OnDropCallback,
    config: Config,
  ) {
    this.forceRender = forceRender
    this.onDrop = onDrop
    this._config = config

    const indicator = this.indicator
    CSSCore.addClass(indicator, 'drag-indicator')
    this.makeClone = config.makeClone
  }

  measureRects = () => {
    queryThenMutateDOMXFramesFromNow(
      () => {
        Object.keys(this._nodes).forEach(id => {
          const node = this._nodes[id]
          if (!node.current) {
            return
          }
          this._rects[id] = getBoundingClientRect(node.current)
        })
      },
      null,
      1,
    )
  }

  dragStart(id: string) {
    queryThenMutateDOM(null, () => {
      document.body.style.cursor = 'move'
    })
    this.dragging = id
    this.forceRender()
    this.measureRects()
  }

  dragEnd() {
    queryThenMutateDOM(null, () => {
      document.body.style.cursor = 'initial'
    })
    if (this.dragging && this._closest) {
      this.onDrop(
        this.dragging,
        this._closest.closestID,
        this._closest.closestDir,
      )
      this._closest = undefined
    }
    this.dragging = null
    if (this._setNotDragging) {
      this._setNotDragging()
    }
    this._setNotDragging = null
    this.forceRender()
    queryThenMutateDOM(null, () => {
      this.indicator.style.opacity = '0'
    })
    this.measureRects()
  }

  setNode(id: string, nodeRef: MutableRefObject<HTMLDivElement>) {
    this._nodes[id] = nodeRef
  }

  findClosestRect() {
    let closestID
    let closestPosX
    let closestPosY
    let height = 0
    let width = 0
    let closestDistance = Infinity
    let closestDirX
    const lastX = this.lastXref.current
    const lastY = this.lastYref.current
    Object.keys(this._rects).forEach(id => {
      const rect = this._rects[id]
      if (!rect) {
        return
      }
      const left = Math.abs(lastX - rect.left)
      const right = Math.abs(lastX - rect.right)
      const top = Math.abs(lastY - rect.top)
      const bot = Math.abs(lastY - rect.bottom)
      let distX = Math.min(left, right)
      let distY = Math.min(top, bot)
      if (rect.left < lastX && lastX < rect.right) {
        distX = 0
      }
      if (rect.top < lastY && lastY < rect.bottom) {
        distY = 0
      }
      const distance = (distX ** 2 + distY ** 2) ** (1 / 2)
      if (closestDistance < distance) {
        return
      }
      closestID = id
      closestDistance = distance
      if (left < right) {
        closestPosX = rect.left
        closestDirX = 'BEFORE'
      } else {
        closestPosX = rect.left + rect.width
        closestDirX = 'AFTER'
      }
      closestPosY = rect.top
      height = rect.height
      width = rect.width
    })
    this.lastYref.current = closestPosY
    this._closest = {
      closestID,
      closestDir: closestDirX,
      closestPos: {
        x: closestPosX,
        y: closestPosY,
        height,
        width,
      },
    }
    return this._closest
  }

  setIndicator(closestPos) {
    this.indicator.style.position = 'absolute'
    this.indicator.style.width = '1px'
    this.indicator.style.transform = `translate(${closestPos.x}px, ${closestPos.y}px)`
    this.indicator.style.opacity = '1'
  }
}
