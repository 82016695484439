import React, { useCallback, useMemo } from 'react'
import { produce } from 'immer'
import { Button, Dropdown } from 'semantic-ui-react'
import Number from 'components/Inputs/Number'
import { usePrevious } from 'hooks/usePrevious'
import { useStateRef } from 'hooks/useStateRef'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { emptyArray } from 'utils/emptyArray'
import { uniqueID } from 'utils/uniqueID'
import { FILTER_OP } from '../constants'
import { GridTableColumnFilterBase } from './GridTableColumnFilterBase'

type FilterArgs = Array<{
  value: number
  setFilter: FilterArgs[0]
  filter:
    | { text: string; value: string; key: string; filter: number[] }
    | { text: string; value: string; key: string; filter: number }
}>
type Props = {
  setFilter: (filterArgs: FilterArgs) => void
  filterArgs: FilterArgs | null
}

export const Component = React.memo(({ setFilter, filterArgs }: Props) => {
  const setFilterRef = useUpdatingRef(setFilter)
  const [conditionsRef, setConditions] = useStateRef(
    filterArgs || [FILTER_TYPES[1]],
  )
  const prevFilterArgs = usePrevious(filterArgs).current
  if (filterArgs !== prevFilterArgs) {
    conditionsRef.current = filterArgs
  }

  const addCondition = useCallback(() => {
    setConditions([...(conditionsRef.current || emptyArray), FILTER_TYPES[1]])
  }, [])

  const clear = useCallback(() => {
    if (!conditionsRef.current.length) {
      return
    }
    if (
      conditionsRef.current.length === 1 &&
      conditionsRef.current[0] !== FILTER_TYPES[1]
    ) {
      setConditions(null)
      submit()
    }
  }, [])

  const getSetFilterVal = useCallback(
    index => filter => {
      setConditions(
        produce(conditionsRef.current, draft => {
          draft.splice(index, 1, filter)
        }),
      )
    },
    [],
  )
  const conditions = conditionsRef.current
    ? // @ts-ignore
      conditionsRef.current.map((cond, i) => {
        return <Condition {...cond} setFilterVal={getSetFilterVal(i)} key={i} />
      })
    : []

  const submit = useCallback(() => {
    setFilterRef.current(
      // @ts-ignore
      conditionsRef.current && conditionsRef.current.length
        ? conditionsRef.current
        : null,
    )
  }, [])

  const menu = useMemo(
    () => (
      <div>
        <h3>Number Filter</h3>
        <div
          className={
            conditions.length > 1
              ? 'grid-table-column-filter-conditions-wrapper'
              : 'single-filter-condition'
          }
        >
          {conditions.length > 1 ? (
            <div className="grid-table-column-filter-conditions-or">
              <div className="line" />
              <div className="or">OR</div>
              <div className="line" />
            </div>
          ) : null}
          {conditions.length === 0 ? (
            <div className="no-conditions">Add Conditions to Filter</div>
          ) : (
            <div className="filter-conditions">{conditions}</div>
          )}
        </div>
        <hr />
        <div className="grid-table-filter-actions">
          <Button size="tiny" onClick={clear} negative content="Clear" />
          <Button
            size="tiny"
            onClick={addCondition}
            primary
            content="Add Condition"
          />
          <Button size="tiny" onClick={submit} positive content="Submit" />
        </div>
      </div>
    ),
    [submit, clear, addCondition, conditions],
  )
  return <GridTableColumnFilterBase menu={menu} />
})

const Condition = React.memo(({ value, filter, setFilterVal }: any) => {
  if (value === FILTER_OP.inRange) {
    return (
      <RangeFilter val={value} filter={filter} setFilterVal={setFilterVal} />
    )
  }
  return (
    <NotRangeFilter val={value} filter={filter} setFilterVal={setFilterVal} />
  )
})

const RangeFilter = React.memo(({ val, filter, setFilterVal }: any) => {
  const [lowValRef, _setLowVal] = useStateRef(filter[0] ? filter[0] : filter)
  const [highValRef, _setHighVal] = useStateRef(filter[1] || 100)
  const onFilterTypeChange = useCallback((_, { value }) => {
    setFilterVal({
      filter: lowValRef.current,
      value,
    })
  }, [])
  const valFilterRef = useUpdatingRef({ val, filter })
  const setHighVal = useCallback(newValue => {
    _setHighVal(newValue)
    setFilterVal({
      filter: [lowValRef.current, highValRef.current],
      value: valFilterRef.current.val,
    })
  }, [])
  const setLowVal = useCallback(newValue => {
    _setLowVal(newValue)
    setFilterVal({
      filter: [lowValRef.current, highValRef.current],
      value: valFilterRef.current.val,
    })
  }, [])

  return (
    <div className="grid-table-number-range-filter">
      <div className="range-filter">
        <Dropdown
          options={FILTER_TYPES}
          value={val}
          onChange={onFilterTypeChange}
        />
        <div className="range-inputs">
          <Number
            value={lowValRef.current}
            onValueChanged={useCallback((_, { value }) => {
              setLowVal(value)
            }, [])}
          />
          <div className="range-filter-separator">{' - '}</div>
          <Number
            value={highValRef.current}
            onValueChanged={useCallback((_, { value }) => {
              setHighVal(value)
            }, [])}
          />
        </div>
      </div>
    </div>
  )
})

const NotRangeFilter = React.memo((props: any) => {
  const { val, filter, setFilterVal } = props
  const propsRef = useUpdatingRef(props)
  const [valRef, _setVal] = useStateRef(filter)
  const onFilterTypeChange = useCallback((_, { value }) => {
    setFilterVal({
      filter: valRef.current,
      value,
    })
  }, [])
  const setVal = useCallback(value => {
    _setVal(value)
    setFilterVal({
      filter: value,
      value: propsRef.current.val,
    })
  }, [])
  return (
    <div>
      <Dropdown
        options={FILTER_TYPES}
        value={val}
        onChange={onFilterTypeChange}
      />
      <Number
        value={filter}
        onValueChanged={useCallback((_, { value }) => {
          setVal(value)
        }, [])}
      />
    </div>
  )
})

const FILTER_TYPES = [
  {
    text: 'In Range',
    value: FILTER_OP.inRange,
    key: uniqueID(),
    filter: [0, 100],
  },
  {
    text: 'Greater than or equal to',
    value: 'gte',
    key: uniqueID(),
    filter: 0,
  },
  {
    text: 'Less than or equal To',
    value: 'lte',
    key: uniqueID(),
    filter: 0,
  },
  {
    text: 'Greater than',
    value: FILTER_OP.greaterThan,
    key: uniqueID(),
    filter: 0,
  },
  {
    text: 'Less than',
    value: FILTER_OP.lessThan,
    key: uniqueID(),
    filter: 0,
  },
  {
    text: 'Equals',
    value: FILTER_OP.equals,
    key: uniqueID(),
    filter: 0,
  },
  {
    text: 'Not equals',
    value: FILTER_OP.notEqual,
    key: uniqueID(),
    filter: 0,
  },
]

function makeFilter(condition): Function {
  switch (condition.value) {
    case 'gte':
      return value => value >= condition.filter
    case FILTER_OP.greaterThan:
      return value => value > condition.filter
    case FILTER_OP.inRange:
      return value => value > condition.filter[0] && value < condition.filter[1]
    case 'lte':
      return value => value <= condition.filter
    case FILTER_OP.lessThan:
      return value => value < condition.filter
    case FILTER_OP.equals:
      // eslint-disable-next-line eqeqeq
      return value => value == condition.filter
    case FILTER_OP.notEqual:
      return value => value !== condition.filter
    default:
      return () => false
  }
}

const map = new WeakMap()
function toFilterFn(condition) {
  if (!map.has(condition)) {
    map.set(condition, makeFilter(condition))
  }
  return map.get(condition)
}
function filterFn(filterArgs, value) {
  if (!filterArgs.length) {
    return false
  }
  const fns = filterArgs.map(toFilterFn)
  let anyPassed = false
  for (const filter of fns) {
    if (filter(value)) {
      anyPassed = true
      break
    }
  }
  return !anyPassed
}
export const GridTableNumberColumnFilter: import('../useGridTable').FilterComponent =
  {
    Klass: Component,
    filterFn,
    toServerModel: filterArgs => {
      return {
        conditions: filterArgs
          ? filterArgs.map(({ filter, value }) => {
              return {
                val: filter,
                op: value,
              }
            })
          : [],
        op: 'or',
      }
    },
  }
