import appFactory from 'App'
import G1BannerManager from 'components/Banner/BannerManager/G1BannerManager'
import GetBannerManager from 'components/Banner/BannerManager/GetBannerManager'

import 'AppGlobalStyles.scss'
import 'styles/client-theme.module.scss'
import { isG1 } from 'utils/envUtils'

const AppHeader = () => (isG1() ? <G1BannerManager /> : <GetBannerManager />)

export default appFactory(<AppHeader />)
