import React from 'react'
import cx from 'classnames'
import format from 'date-fns/format'
import SettlementNote from 'components/Settlements/components/SettlementNote'
import { getTimezone } from 'utils/getTimezone'
import Styles from './GalaxyHeader.module.scss'

interface GalaxyHeader {
  title?: string
  subTitle?: React.ReactNode
  border?: boolean
  showAsOf?: boolean
  children?: React.ReactNode
  extraInfo?: React.ReactNode
  customProps?: {
    settlementNote?: string
    accountId?: number
  }
  asOfDate?: string | number | Date
  flex?: boolean
}

const GalaxyHeader = ({
  title,
  children,
  subTitle,
  border = true,
  showAsOf = false,
  asOfDate = new Date(),
  extraInfo,
  customProps,
  flex = false,
}: GalaxyHeader) => (
  <div
    className={cx({
      'galaxy-header': !flex,
      'galaxy-header-flex': flex,
    })}
  >
    <div className={cx({ flex: flex })}>
      <div className={Styles['titleRow']}>
        {title && <h1>{title}</h1>} {extraInfo}
      </div>
      {subTitle && <h3>{subTitle}</h3>}
      {customProps?.settlementNote !== undefined && (
        <SettlementNote
          note={customProps?.settlementNote}
          accountId={customProps?.accountId}
        />
      )}
      {showAsOf && (
        <em className="subheader">
          as of {format(asOfDate, 'dddd, MMMM D, YYYY h:mm:ss A')}{' '}
          {getTimezone(new Date())}
        </em>
      )}
    </div>
    <div>
      {border && <div className="header-border" />}
      {children}
    </div>
  </div>
)

export default GalaxyHeader
