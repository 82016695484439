import { SvgIconsMap } from '../types'
import Add from './ic-add.svg'
import AlertRound from './ic-alert-round.svg'
import AlertTriangle from './ic-alert-triangle.svg'
import ArrowDown from './ic-arrow-down.svg'
import ArrowLeft from './ic-arrow-left.svg'
import ArrowRight from './ic-arrow-right.svg'
import ArrowUp from './ic-arrow-up.svg'
import Calendar from './ic-calendar.svg'
import Camera from './ic-camera.svg'
import Check from './ic-check.svg'
import ChevronDown from './ic-chevron-down.svg'
import ChevronLeft from './ic-chevron-left.svg'
import ChevronRight from './ic-chevron-right.svg'
import ChevronUp from './ic-chevron-up.svg'
import Clock from './ic-clock.svg'
import Close from './ic-close.svg'
import Cog from './ic-cog.svg'
import Copy from './ic-copy.svg'
import Filter from './ic-filter.svg'
import Flip from './ic-flip.svg'
import Graph from './ic-graph.svg'
import Help from './ic-help.svg'
import Info from './ic-info.svg'
import Logout from './ic-logout.svg'
import MoreHorz from './ic-more-horz.svg'
import Notifications from './ic-notifications.svg'
import Placeholder from './ic-placeholder.svg'
import Search from './ic-search.svg'
import Selector from './ic-selector.svg'
import Settings from './ic-settings.svg'
import Smartphone from './ic-smartphone.svg'
import Target from './ic-target.svg'
import Transfer from './ic-transfer.svg'

export const IconUrlMap: SvgIconsMap = {
  add: Add,
  'alert-round': AlertRound,
  'alert-triangle': AlertTriangle,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  calendar: Calendar,
  camera: Camera,
  check: Check,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  clock: Clock,
  close: Close,
  cog: Cog,
  copy: Copy,
  filter: Filter,
  flip: Flip,
  graph: Graph,
  help: Help,
  info: Info,
  logout: Logout,
  'more-horz': MoreHorz,
  notifications: Notifications,
  placeholder: Placeholder,
  search: Search,
  selector: Selector,
  settings: Settings,
  smartphone: Smartphone,
  target: Target,
  transfer: Transfer,
}
