import produce from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/historicalBlotter'
import { Type as UserType } from 'redux/actions/user'
import { HistoricalBlotterModel } from 'redux/models'
import getSelectedStrategy from 'redux/selectors/getSelectedStrategy'
import { registerSaver } from 'redux/store/utils'
import { getState } from 'utils/getState'
import {
  STRATEGY_ID_MANUAL,
  STRATEGY_ID_GOTC,
  STRATEGY_ID_GDT_OTC,
} from 'utils/RefDataHelper'
import { REDUX_INITIAL_SET } from '../store/localStorage'

export enum Filter {
  ALL_ACTIVITY = 0,
  ALL_OTC = 1,
  MANUAL_OTC = 2,
  G_OTC = 3,
  EXTERNAL_EXCHANGES = 4,
}

export const DEFAULT_FILTER = Filter.MANUAL_OTC

function createFilter(value, text) {
  return { key: value, value, text }
}

export const FILTER_DICT = {
  [Filter.ALL_ACTIVITY]: createFilter(Filter.ALL_ACTIVITY, 'All Activity'),
  [Filter.ALL_OTC]: createFilter(Filter.ALL_OTC, 'All OTC'),
  [Filter.MANUAL_OTC]: createFilter(Filter.MANUAL_OTC, 'Manual OTC'),
  [Filter.G_OTC]: createFilter(Filter.G_OTC, 'G-OTC'),
  [Filter.EXTERNAL_EXCHANGES]: createFilter(
    Filter.EXTERNAL_EXCHANGES,
    'External Exchanges',
  ),
}

export const FILTER_OPTIONS = [
  FILTER_DICT[Filter.ALL_OTC],
  FILTER_DICT[Filter.MANUAL_OTC],
  FILTER_DICT[Filter.G_OTC],
  FILTER_DICT[Filter.EXTERNAL_EXCHANGES],
]

export const FILTERS_BY_STRATEGY = {
  [STRATEGY_ID_MANUAL]: [
    FILTER_DICT[Filter.MANUAL_OTC],
    FILTER_DICT[Filter.EXTERNAL_EXCHANGES],
  ],
  [STRATEGY_ID_GDT_OTC]: [
    FILTER_DICT[Filter.ALL_OTC],
    FILTER_DICT[Filter.MANUAL_OTC],
    FILTER_DICT[Filter.G_OTC],
    FILTER_DICT[Filter.EXTERNAL_EXCHANGES],
  ],
  [STRATEGY_ID_GOTC]: [
    FILTER_DICT[Filter.ALL_ACTIVITY],
    FILTER_DICT[Filter.G_OTC],
    FILTER_DICT[Filter.EXTERNAL_EXCHANGES],
  ],
}

const initialState: HistoricalBlotterModel = {
  filter: DEFAULT_FILTER,
  filterOptions: FILTER_OPTIONS,
  complianceView: false,
  operationsView: false,
  firmSideView: false,
  showCanceledOrders: false,
}

registerSaver(state => {
  const s = state.HistoricalBlotter
  return {
    HistoricalBlotter: {
      filter: s.filter,
      operationsView: s.operationsView,
      complianceView: s.complianceView,
      showCanceledOrders: s.showCanceledOrders,
    },
  }
})

export const HistoricalBlotterReducer = handleActions<
  HistoricalBlotterModel,
  any
>(
  {
    [REDUX_INITIAL_SET]: (state, { payload }) => {
      return produce(state, draft => {
        const persistedState = payload.HistoricalBlotter || {}
        delete persistedState.filterOptions
        Object.assign(draft, persistedState)
      })
    },
    [Type.UPDATE_FIELD]: (state, { payload }) => {
      return produce(state, draft => {
        const { field, val } = payload
        draft[field] = val
      })
    },
    [UserType.SELECTED_STRATEGY_CHANGED_AFTER]: state => {
      const selectedStrategy = getSelectedStrategy(getState())
      if (!selectedStrategy) {
        return state
      }
      return produce(state, draft => {
        const filters = FILTERS_BY_STRATEGY[selectedStrategy.id]
        if (!filters || !filters.length) {
          draft.filter = null
          draft.filterOptions = []
          return
        }
        draft.filterOptions = filters
        draft.filter = filters[0].value
      })
    },
  },
  initialState,
)
