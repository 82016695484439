import 'react-dates/initialize'

import React, { FC, memo } from 'react'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { Provider } from 'react-redux'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { useRouter } from 'next/router'
import { ThemeProvider } from 'styled-components'

import { useIsFirstRender } from 'hooks/useIsFirstRender'
import { store } from 'store'
import styledTheme from 'styles/styled-components'
import errorToast from 'utils/errorToast'
import { isBrowser } from 'utils/isBrowser'
import oktaAuth from 'utils/OktaConfig'
import sentry from 'utils/sentry'

import { GalaxyAppDocument } from './components/GalaxyAppDocument'
import { SecuredContent } from './components/SecuredContent'

const { captureException } = sentry()

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: Error, { queryKey }) => {
      captureException(error, { errorInfo: { queryKey } })
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, { options }) => {
      captureException(error, {
        errorInfo: { mutationKey: options.mutationKey },
      })
    },
  }),
  defaultOptions: {
    queries: {
      retry: 2,
      onError: () => {
        errorToast()
      },
    },
    mutations: {
      onError: () => {
        errorToast()
      },
    },
  },
})

type GalaxyAppBaseProps = {
  appHeader: React.ReactElement
  onPageRender(): React.ReactElement
}

const GalaxyAppBase: FC<GalaxyAppBaseProps> = ({ appHeader, onPageRender }) => {
  const isFirstRender = useIsFirstRender(true)
  const router = useRouter()
  const isImplicitCallback = router.pathname === '/implicit/callback'

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string,
  ) => {
    router.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const appPage: React.ReactElement =
    isBrowser && !isFirstRender ? (
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        {isImplicitCallback ? (
          onPageRender()
        ) : (
          <SecuredContent appHeader={appHeader} page={onPageRender()} />
        )}
      </Security>
    ) : null

  return (
    <GalaxyAppDocument>
      <ThemeProvider theme={styledTheme}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store()}>{appPage}</Provider>
        </QueryClientProvider>
      </ThemeProvider>
    </GalaxyAppDocument>
  )
}

export default memo(GalaxyAppBase)
