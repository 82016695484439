import React, {
  useLayoutEffect,
  useRef,
  ReactElement,
  useCallback,
  ReactChild,
  useContext,
  useMemo,
} from 'react'
import cx from 'classnames'
import { PortalWrapper } from 'components/PortalWrapper'
import { useEffectNow } from 'hooks/useEffectNow'
import { useListener, PASSIVE } from 'hooks/useListener'
import { useStateRef } from 'hooks/useStateRef'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { getBoundingClientRect } from 'utils/getBoundingClientRect'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'
import { getWidth, getHeight } from 'utils/windowDimensions'

export enum VISIBILITY {
  HOVER = 1,
  VISIBLE = 2,
  NONE = 3,
}

type Props = {
  children: ReactChild | ReactChild[]
  tooltip: ReactElement
  customStyles?: boolean
  vertical?: 'top' | 'bottom' | 'bottomFromTop' | 'topFromBottom'
  horizontal?: 'left' | 'right' | 'rightFromLeft' | 'leftFromRight'
  visibility: VISIBILITY
  className?: string
  onClick?: any
}

const tooltips: Function[] = []
function register(updatePosition: Function) {
  tooltips.push(updatePosition)
  if (tooltips.length === 1) {
    startInterval()
  }
}
function unregister(updatePosition: Function) {
  const idx = tooltips.indexOf(updatePosition)
  tooltips.splice(idx, 1)
  if (tooltips.length === 0) {
    stopInterval()
  }
}
let intervalID
function startInterval() {
  clearInterval(intervalID)
  intervalID = setInterval(() => {
    tooltips.forEach(fn => fn())
  }, 1000)
}
function stopInterval() {
  clearInterval(intervalID)
}

const contextFactory = () => {
  const tooltips = []
  return {
    tooltipContext: tooltips,
    register(tooltip) {
      tooltips.push(tooltip)
    },
    unregister(tooltip) {
      const index = tooltips.indexOf(tooltip)
      if (index !== -1) {
        tooltips.splice(index, 1)
      }
    },
    contains(slice = 0, element) {
      return tooltips.slice(slice).some(tooltip => {
        const t = tooltip()
        return t && t.contains(element)
      })
    },
    hasContext: () => !!tooltips.length,
    contextLength: () => tooltips.length,
  }
}

const TooltipContext = React.createContext(contextFactory())

export const useToolTipContext = tooltipRef => {
  const context = useContext(TooltipContext)
  const providerContext = useRef<typeof context>(null)
  const levelRef = useRef(0)
  useEffectNow(() => {
    if (providerContext.current || levelRef.current) {
      return
    }
    if (!context.hasContext()) {
      providerContext.current = contextFactory()
    } else {
      levelRef.current = context.contextLength()
    }
  }, [])
  const contextRef = useRef(providerContext.current || context)
  useEffectNow(() => {
    const cb = () => tooltipRef.current
    contextRef.current.register(cb)
    return () => {
      contextRef.current.unregister(cb)
    }
  }, [])

  const renderer = useCallback(content => {
    if (!providerContext.current) {
      return content
    }
    return (
      <TooltipContext.Provider value={providerContext.current}>
        {content}
      </TooltipContext.Provider>
    )
  }, [])
  return {
    renderer,
    context: useMemo(
      () => ({
        ...contextRef.current,
        contains: element => {
          return contextRef.current.contains(
            Math.max(0, levelRef.current - 1),
            element,
          )
        },
      }),
      [],
    ),
  }
}

const TooltipImpl = (props: Props) => {
  const { visibility, className: _className, horizontal, vertical } = props
  const className = cx({
    default: !props.customStyles,
    [_className]: !!_className,
  })
  const settingsRef = useUpdatingRef({ horizontal, vertical })
  const tooltipRef = useRef<HTMLDivElement>(null)
  const relativeRef = useRef<HTMLDivElement>(null)

  const scheduledRef = useRef<boolean>(false)
  const [hoveringRef, setHovering] = useStateRef(false)
  useEffectNow(() => {
    hoveringRef.current = false
  }, [visibility])
  const hovering = hoveringRef.current

  const dataRef = useUpdatingRef({ hovering, visibility })
  const offsetsRef = useRef({ h: 0, v: 0 })

  const updatePosition = useCallback(() => {
    if (scheduledRef.current) {
      return
    }
    const data = dataRef.current

    const active =
      (data.hovering && data.visibility === VISIBILITY.HOVER) ||
      data.visibility === VISIBILITY.VISIBLE

    if (!active) {
      return
    }
    const relative = relativeRef.current
    const tooltip = tooltipRef.current
    const contentWrapper = contentWrapperRef.current

    if (!relative || !tooltip || !contentWrapper) {
      tooltip && tooltip.remove()
      return
    }

    scheduledRef.current = true
    let contentWrapperBoxReal: DOMRect
    queryThenMutateDOM(
      () => {
        if (contentWrapper) {
          // @ts-ignore
          contentWrapperBoxReal = contentWrapper.getBoundingClientRect()
        }
        const relative = relativeRef.current
        return relative && getBoundingClientRect(relative)
      },
      (rect: ReturnType<typeof getBoundingClientRect> | null) => {
        scheduledRef.current = false
        const tooltip = tooltipRef.current
        if (!rect || !tooltip || !contentWrapperBoxReal) {
          return
        }
        const _rect = rect._rect
        const isLeft = settingsRef.current.horizontal === 'left'
        const isRight = settingsRef.current.horizontal === 'right'
        const isRightFLeft = settingsRef.current.horizontal === 'rightFromLeft'
        const isLeftFRight = settingsRef.current.horizontal === 'leftFromRight'
        const isTop = settingsRef.current.vertical === 'top'
        const isBottom = settingsRef.current.vertical === 'bottom'
        const isTopFBot = settingsRef.current.vertical === 'topFromBottom'
        const isBotFTop = settingsRef.current.vertical === 'bottomFromTop'
        const height = contentWrapperBoxReal.height
        const width = contentWrapperBoxReal.width
        let top, bottom, left, right
        if (isTop) {
          top = rect.top - height
          bottom = rect.top
        } else if (isTopFBot) {
          top = rect.bottom - height
          bottom = rect.bottom
        } else if (isBottom) {
          top = rect.bottom
          bottom = rect.bottom + height
        } else if (isBotFTop) {
          top = rect.top
          bottom = rect.top + height
        }
        if (isLeft) {
          left = rect.left - width
          right = rect.left
        } else if (isLeftFRight) {
          left = rect.right - width
          right = rect.right
        } else if (isRight) {
          left = rect.right
          right = rect.right + width
        } else if (isRightFLeft) {
          left = rect.left
          right = rect.left + width
        }
        const windowWidth = getWidth()
        const windowHeight = getHeight()
        const isTopHidden = top - window.scrollY < NAV_HEIGHT + WINDOW_PAD
        const isBottomHidden =
          bottom - window.scrollY > windowHeight - WINDOW_PAD
        const isLeftHidden = left - window.scrollX < WINDOW_PAD
        const isRightHidden = right - window.scrollX > windowWidth - WINDOW_PAD
        const isRelativeVisible =
          (_rect.left >= 0 ||
            _rect.left <= windowWidth ||
            _rect.right >= 0 ||
            _rect.right <= windowWidth) &&
          (_rect.top >= NAV_HEIGHT ||
            _rect.top <= windowHeight ||
            _rect.bottom >= NAV_HEIGHT ||
            _rect.bottom <= windowHeight)

        let h = 0
        let v = 0
        if (isLeftHidden && isRelativeVisible) {
          h += 0 - left + WINDOW_PAD
        }
        if (isRightHidden && isRelativeVisible) {
          const over = right - windowWidth + WINDOW_PAD
          h -= over
        }
        if (isBottomHidden && isRelativeVisible) {
          const over = bottom - windowHeight - WINDOW_PAD
          v -= over
        }
        if (isTopHidden && isRelativeVisible) {
          const over = NAV_HEIGHT - top + WINDOW_PAD
          v += over
        }
        offsetsRef.current.v = v
        offsetsRef.current.h = h
        tooltip.style.width = rect.width + 'px'
        tooltip.style.top = top + offsetsRef.current.v + 'px'
        tooltip.style.left = left + offsetsRef.current.h + 'px'
        tooltip.style.height = rect.height + 'px'
      },
    )
  }, [])

  useListener(document.body, 'scroll', updatePosition, PASSIVE)
  useListener(window, 'resize', updatePosition, PASSIVE)

  const { context, renderer } = useToolTipContext(tooltipRef)

  const mousemoveListener = useCallback(
    e => {
      const data = dataRef.current
      const active = data.hovering && data.visibility === VISIBILITY.HOVER
      if (!active) {
        return
      }
      const tooltip = tooltipRef.current
      const relative = relativeRef.current
      if (!tooltip || !relative) {
        return
      }
      if (!context.contains(e.target) && !relative.contains(e.target)) {
        setHovering(false)
        document.body.removeEventListener('mousemove', mousemoveListener)
      }
    },
    [context],
  )

  const active =
    (hovering && visibility === VISIBILITY.HOVER) ||
    visibility === VISIBILITY.VISIBLE
  useLayoutEffect(updatePosition, [props.children, active])
  useLayoutEffect(() => {
    register(updatePosition)
    return () => {
      unregister(updatePosition)
    }
  }, [])

  const tooltipClassName =
    'galaxy-tooltip-wrapper h-' +
    horizontal +
    ' v-' +
    vertical +
    ' ' +
    className +
    (active ? ' active' : '')

  const contentWrapperRef = useRef<HTMLDivElement>()
  const tooltip = (
    <div ref={tooltipRef} className={tooltipClassName} onClick={props.onClick}>
      <div className={'galaxy-tooltip-wrapper-2'}>
        <div className={'galaxy-tooltip-wrapper-3'}>
          <div className={'galaxy-tooltip-wrapper-4'} ref={contentWrapperRef}>
            {active ? props.tooltip : null}
          </div>
        </div>
      </div>
    </div>
  )

  const mouseEnter = useCallback(() => {
    setHovering(true)
    document.body.addEventListener('mousemove', mousemoveListener)
  }, [])
  let wrapperProps = {}
  if (visibility === VISIBILITY.HOVER) {
    wrapperProps = {
      onMouseEnter: mouseEnter,
    }
  }

  const content = (
    <div className="Tooltip-root" ref={relativeRef}>
      <div {...wrapperProps} className="Tooltip-wrapper">
        {props.children}
      </div>
      {active ? <PortalWrapper>{tooltip}</PortalWrapper> : null}
    </div>
  )

  return renderer(content)
}

TooltipImpl.defaultProps = {
  customStyles: false,
  vertical: 'bottomFromTop',
  horizontal: 'right',
  visibility: VISIBILITY.HOVER,
}

const NAV_HEIGHT = 72
const WINDOW_PAD = 8
export const Tooltip = React.memo(TooltipImpl)
