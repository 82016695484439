import moment from 'moment-timezone'
import { GridHelper } from 'components/Galaxy/index'
import { getPNLData } from 'data/pnl'
import { Type } from 'redux/actions/liveRisk'
import { AssetStore, Strategy } from 'redux/models'
import {
  RiskLineItem,
  PositionValue,
  ExposureItem,
} from 'redux/models/RiskLineItem'
import { getKeyedDispatchQueue } from 'utils/dispatch'
import emptyFunction from 'utils/emptyFunction'
import { getState } from 'utils/getState'
import { get } from 'utils/http'
import { toQueryString } from 'utils/QueryString'
import {
  ASSET_STORE_TYPE_EXCHANGE,
  ASSET_STORE_TYPE_HOT_WALLET,
  ASSET_STORE_TYPE_COLD_WALLET,
  ASSET_STORE_TYPE_BANK,
} from 'utils/RefDataHelper'

const batchedDispatch = getKeyedDispatchQueue()
const baseUrl = process.env.NEXT_PUBLIC_STATE_SERVICE_META_BASE_URL as string

export function sortAssetStores(a: AssetStore, b: AssetStore): number {
  const sortByType = sortAssetStoryType(a.type, b.type)
  if (sortByType != null) {
    return sortByType as number
  }
  if (a.sortOrder != null) {
    if (b.sortOrder != null) {
      if (a.sortOrder < b.sortOrder) return -1
      if (a.sortOrder > b.sortOrder) return 1
    } else {
      return -1
    }
  } else if (b.sortOrder != null) {
    return 1
  }
  return 0
}

export function getLiveRiskData(
  selectedStrategy: Strategy,
  onSuccess = emptyFunction,
) {
  return getPortfolioDataBase(selectedStrategy.id, data => {
    if (data.securities) {
      batchedDispatch('securities', {
        type: Type.SET_SECURITIES,
        payload: data.securities,
      })
    }
    if (data.assetStores) {
      batchedDispatch('exchanges', {
        type: Type.SET_EXCHANGES,
        payload: {
          data: data.assetStores.sort(sortAssetStores),
        },
      })
    }
    batchedDispatch('exposures', {
      type: Type.SET_EXPOSURES,
      payload: {
        data: data.exposures,
      },
    })
    onSuccess()
  })
}

export function getPortfolioDataBase(
  strategyId,
  onSuccess: (data: any) => void = emptyFunction,
  includeMeta = true,
  includePnl = false,
  analysisToDate = null,
) {
  const dss: ReturnType<typeof get>[] = []
  new Promise((res, rej) => {
    let _meta = includeMeta ? undefined : null
    let _data
    let _pnl = includePnl ? undefined : null
    let _pnlPrevDay = includePnl ? undefined : null
    let _pnlAnalysis = analysisToDate ? undefined : null
    let _pnlDaily = includePnl ? undefined : null
    function onDone() {
      if (
        _meta !== undefined &&
        _data !== undefined &&
        _pnl !== undefined &&
        _pnlPrevDay !== undefined &&
        _pnlAnalysis !== undefined &&
        _pnlDaily !== undefined
      ) {
        res({
          meta: _meta,
          records: _data,
          pnl: _pnl,
          pnlPrevDay: _pnlPrevDay,
          pnlAnalysis: _pnlAnalysis,
          pnlDaily: _pnlDaily,
        })
      }
    }
    if (includeMeta) {
      dss.push(
        get(baseUrl, `meta?${toQueryString({ strategyId })}`, meta => {
          _meta = meta || null
          onDone()
        }),
      )
    }
    if (includePnl) {
      dss.push(
        getPNLData({ strategyId }, data => {
          _pnl = data || null
          onDone()
        }),
      )
      dss.push(
        getPNLData({ strategyId, toDate: yesterday5pm() }, data => {
          _pnlPrevDay = data || null
          onDone()
        }),
      )
      dss.push(
        getPNLData(
          { strategyId, fromDate: yesterday5pm(), toDate: new Date() },
          data => {
            _pnlDaily = data || null
            onDone()
          },
        ),
      )
      if (analysisToDate) {
        dss.push(
          getPNLData({ strategyId, toDate: analysisToDate }, data => {
            _pnlAnalysis = data || null
            onDone()
          }),
        )
      }
    }
    dss.push(
      get(
        baseUrl,
        `state?${toQueryString({ strategyId })}`,
        data => {
          _data = data || null
          onDone()
        },
        undefined,
        false,
      ),
    )
  }).then((finalData: any) => {
    const data: any = {}
    const meta = finalData.meta
    if (meta) {
      if (meta.securities) {
        data.securities = meta.securities
      }
      if (meta.assetStores) {
        data.assetStores = meta.assetStores
      }
    }
    data.pnl = finalData.pnl
    data.pnlPrevDay = finalData.pnlPrevDay
    data.pnlAnalysis = finalData.pnlAnalysis
    data.pnlDaily = finalData.pnlDaily
    const exposureResult: any = finalData.records ? finalData.records : []
    if (exposureResult) {
      const localdata: ExposureItem[] = []

      const otcData = exposureResult!.filter((exp: RiskLineItem) => {
        const strategyId = exp.strategyId
        return exp && strategyId //=== selectedStrategy.id
      })

      otcData.forEach((element: RiskLineItem) => {
        const item: ExposureItem = element
        element &&
          element.positions &&
          element.positions.map(
            (ed: PositionValue) => (item[ed.symbol] = ed.qty),
          )
        element.id = GridHelper().getRowNodeId(element)
        localdata.push(item)
      })

      data.exposures = localdata
    }
    onSuccess(data)
  })
  return {
    cancel: () => {
      dss.forEach(ds => ds.cancel())
    },
  }
}

const ASSET_TYPE_ORDER = {
  [ASSET_STORE_TYPE_EXCHANGE.toLowerCase()]: 0,
  [ASSET_STORE_TYPE_HOT_WALLET.toLowerCase()]: 1,
  [ASSET_STORE_TYPE_COLD_WALLET.toLowerCase()]: 2,
  [ASSET_STORE_TYPE_BANK.toLowerCase()]: 3,
}

export function sortAssetStoryType(a: string, b: string): number | void {
  const aTOrder = ASSET_TYPE_ORDER[a.toLowerCase()]
  const bTOrder = ASSET_TYPE_ORDER[b.toLowerCase()]
  if (aTOrder != null) {
    if (bTOrder != null) {
      if (aTOrder < bTOrder) return -1
      if (bTOrder < aTOrder) return 1
    } else {
      return -1
    }
  } else if (bTOrder != null) {
    return 1
  }
}

const restUrl = process.env.NEXT_PUBLIC_TRADE_RISK_SERVICE_REST_URL
export function getOrders(
  type: 'exchangeOpen' | 'cpFilled' | 'exchangeFilled',
  currency: string,
  onSuccess = emptyFunction,
) {
  let mappedType
  switch (type) {
    case 'exchangeOpen':
      mappedType = 'open'
      break
    case 'cpFilled':
      mappedType = 'cp-fills'
      break
    case 'exchangeFilled':
      mappedType = 'exchange-fills'
  }
  const interval = getState().LiveRisk.tradeDataInterval
  if (interval.length === 1) {
    return get(
      restUrl,
      `${mappedType}?${toQueryString({
        symbol: currency,
        interval: interval[0],
        strategy: 25,
      })}`,
      orders => {
        batchedDispatch('tradeOrders', {
          type: Type.SET_TRADE_ORDERS,
          payload: { currency, orders, type },
        })
        onSuccess(orders)
      },
    )
  } else {
    return get(
      restUrl,
      `${mappedType}?${toQueryString({
        symbol: currency,
        range: {
          from: interval[0],
          to: interval[1],
        },
        strategy: 25,
      })}`,
      orders => {
        batchedDispatch('tradeOrders', {
          type: Type.SET_TRADE_ORDERS,
          payload: { currency, orders, type },
        })
        onSuccess(orders)
      },
    )
  }
}

function yesterday5pm() {
  let days = 0
  if (moment().tz('America/New_York').hour() < 17) {
    days += 1
  }
  return moment()
    .tz('America/New_York')
    .set({
      hour: 17, // 5pm
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .subtract(days, 'days')
    .toDate()
}
