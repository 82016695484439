import emptyFunction from 'utils/emptyFunction'
import { get } from 'utils/http'
import { toQueryString } from 'utils/QueryString'

const pnlServiceUrl = process.env.NEXT_PUBLIC_PNL_SERVICE_BASE_URL as string

type PNLDataProps = {
  strategyId: string | number
  toDate?: Date
  fromDate?: Date
}
export function getPNLData(
  { strategyId, toDate, fromDate }: PNLDataProps,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    pnlServiceUrl,
    `pnl?${toQueryString({
      strategyId,
      fromDate: fromDate ? fromDate.toISOString() : null,
      toDate: toDate ? toDate.toISOString() : null,
    })}`,
    onSuccess,
    onError,
  )
}
