import deepEqual from 'react-fast-compare'
import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/settlements'
import {
  SettlementModel,
  SettlementTrade,
  OTCInventoryPayload,
  CPOutstandingPayload,
} from 'redux/models/settlements'
import { registerSaver } from 'redux/store/utils'
import { REDUX_INITIAL_SET } from '../store/localStorage'

const initialState: SettlementModel = {
  galaxyInventory: null,
  settlementData: {}, // store full settlement data by settlement ID
  unsettledTrades: {}, // UnsettledTrades by CP
  unallocatedTransfers: {}, // UnallocatedTransfer by CP
  historicalSettlementsByCP: {}, // store historical settlement summary(not full) data by CP

  // literally only used to get the CP Name
  outstandingSettlementsByCP: {},
  currencyNames: {},

  settings: {
    showInternalTraders: true,
    showPendingOnly: true,
  },

  failedAutoSettlementAndSweepCpIds: [],
}

registerSaver(state => {
  const s = state.settlement
  return {
    settlement: {
      settings: s.settings,
    },
  }
})

export const settlementReducer = handleActions<SettlementModel, any>(
  {
    [REDUX_INITIAL_SET]: (state, { payload }) => {
      return produce(state, draft => {
        const persistedState = payload.settlement || {}
        Object.assign(draft, persistedState)
      })
    },
    [Type.UPDATE_UNSETTLED_TRADES]: (
      state: SettlementModel,
      { payload: { unsettledTradePayload, id } },
    ) => {
      return produce(state, draft => {
        const currentUnsettledTrade = state.unsettledTrades[id] || []
        unsettledTradePayload.forEach(trade => {
          if (!trade.strategy) {
            trade.strategy = {
              id: null,
              name: null,
            }
          }
        })

        if (
          !currentUnsettledTrade ||
          !currentUnsettledTrade.length ||
          !deepEqual(currentUnsettledTrade, unsettledTradePayload)
        ) {
          draft.unsettledTrades[id] = unsettledTradePayload
          unsettledTradePayload.forEach((trade: SettlementTrade) => {
            draft.currencyNames[trade.baseCcySecurityId] = trade.baseCcySymbol
            draft.currencyNames[trade.counterCcySecurityId] =
              trade.counterCcySymbol
          })
        }
      })
    },
    [Type.UPDATE_UNNALLOCATED_TRANSFERS]: (
      state: SettlementModel,
      { payload },
    ) => {
      return produce(state, (draft: SettlementModel) => {
        if (!deepEqual(payload.data, state.unallocatedTransfers[payload.id])) {
          draft.unallocatedTransfers[payload.id] = payload.data
        }
      })
    },
    [Type.SET_OUTSTANDING_SETTLEMENTS_BY_CP]: (
      state: SettlementModel,
      { payload }: { payload: CPOutstandingPayload },
    ) => {
      return produce(state, (draft: SettlementModel) => {
        const outstandingSettlementsByCP = {}
        payload.forEach(p => {
          outstandingSettlementsByCP[p.counterparty.id] = p
          draft.outstandingSettlementsByCP[p.counterparty.id] = p
        })
        Object.keys(draft.outstandingSettlementsByCP).forEach(key => {
          if (!outstandingSettlementsByCP[key]) {
            delete draft.outstandingSettlementsByCP[key]
          } else {
            if (
              !deepEqual(
                draft.outstandingSettlementsByCP[key],
                outstandingSettlementsByCP[key],
              )
            ) {
              draft.outstandingSettlementsByCP[key] =
                outstandingSettlementsByCP[key]
            }
          }
        })
      })
    },
    [Type.SET_CPS_WITH_FAILED_AUTO_SETTLEMENTS_OR_SWEEPS]: (
      state: SettlementModel,
      { payload }: { payload: number[] },
    ) => {
      return produce(state, (draft: SettlementModel) => {
        draft.failedAutoSettlementAndSweepCpIds = payload
      })
    },
    [Type.SET_HISTORICAL_SETTLEMENTS]: (
      state: SettlementModel,
      // @ts-expect-error The payload type doesn't specifies `id` property
      { payload, id },
    ) => {
      return produce(state, (draft: SettlementModel) => {
        draft.historicalSettlementsByCP[id] = payload
      })
    },
    [Type.TOGGLE_SETTING]: (state: SettlementModel, { payload }) => {
      return produce(state, draft => {
        const { key } = payload
        draft.settings[key] = !draft.settings[key]
      })
    },
    [Type.SET_SETTLEMENT]: (state: SettlementModel, { payload }) => {
      return produce(state, draft => {
        const { id, data } = payload
        if (!deepEqual(state.settlementData[id], data)) {
          draft.settlementData[id] = data
        }
      })
    },
    [Type.SET_OTC_INVENTORY]: (
      state: SettlementModel,
      { payload }: { payload: OTCInventoryPayload },
    ) => {
      return produce(state, draft => {
        if (!deepEqual(state.galaxyInventory, payload)) {
          draft.galaxyInventory = payload
        }
      })
    },
  },
  initialState,
)
