import { STRATEGY } from 'components/Portfolio/constants'
import { setEODPrices, setDerivativeData } from 'redux/actions/portfolio'
import emptyFunction from 'utils/emptyFunction'
import { get } from 'utils/http'

const baseUrl = process.env.NEXT_PUBLIC_REFERENCE_SERVICE_BASE_URL
const derivativeUrl = process.env.NEXT_PUBLIC_RISK_SERVER_URL

export function getEODPrices() {
  return get(
    baseUrl,
    'api/prices/eod',
    setEODPrices,
    emptyFunction,
    false,
  ).retry(5000, 5)
}

export function getGDLPDerivativeData(
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    derivativeUrl,
    'macro/positions',
    data => {
      setDerivativeData(STRATEGY.GDLP_DERIVATIVE, data)
      onSuccess(data)
    },
    onError,
    false,
  ).retry(5000, 5)
}

export type DerivDataKey =
  | typeof STRATEGY.BHAR_DERIVATIVE
  | typeof STRATEGY.TREY_DERIVATIVE
  | typeof STRATEGY.SPST_DERIVATIVE

const map: Record<DerivDataKey, string> = {
  [STRATEGY.BHAR_DERIVATIVE]: 'bhar',
  [STRATEGY.TREY_DERIVATIVE]: 'trey',
  [STRATEGY.SPST_DERIVATIVE]: 'spst',
}
export function getDerivativeData(
  strategy: DerivDataKey,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return get(
    derivativeUrl,
    `${map[strategy]}/positions`,
    data => {
      setDerivativeData(strategy, data)
      onSuccess(data)
    },
    onError,
    false,
  ).retry(5000, 5)
}
