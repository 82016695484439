import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Modal, Typography, LoadingIndicator } from '@g1/components'
import { selectIsSigningOut } from 'redux/selectors/selectGalaxyUser'

import Styles from './SignOutModal.module.scss'

export const SignOutModal: FC = () => {
  const isSigningOut = useSelector(selectIsSigningOut)
  return (
    <Modal open={isSigningOut}>
      <Modal.Content>
        <div className={Styles.container}>
          <LoadingIndicator />
          <Typography variant="h4">Signing Out</Typography>
          <Typography variant="body2">
            One moment while we process your request.
          </Typography>
        </div>
      </Modal.Content>
    </Modal>
  )
}
