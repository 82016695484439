import { Draft, produce } from 'immer'
import { ReducerMap, Action, handleActions } from 'redux-actions'
import { REDUX_INITIAL_SET } from 'redux/store/localStorage'
import { rethrowError } from 'utils/rethrowError'

type Actions<R, S> = {
  [key: string]: (draftState: Draft<R>, payload: S) => void
}

// Handle actions... but pre-immer-fied!
export default <R, S>(actions: Actions<R, S>, initialState: R) => {
  const copy: ReducerMap<R, S> = {}
  Object.keys(actions).forEach(key => {
    let fn
    if (key === REDUX_INITIAL_SET) {
      fn = (state: R, data: Action<S>) => {
        return produce(state, draft => {
          try {
            actions[key](draft, data.payload)
          } catch (e) {
            rethrowError(e)
          }
        })
      }
    } else {
      fn = (state: R, data: Action<S>) => {
        return produce(state, draft => {
          actions[key](draft, data.payload)
        })
      }
    }
    copy[key] = fn
  })
  return handleActions<R, S>(copy, initialState)
}
