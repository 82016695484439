import React, { FC } from 'react'
import { WarningIcon, Typography } from '@g1/components'
import { Message } from '../../../hooks/useMaintenanceIntervals'

type Props = {
  message: Message
}

const InfoBanner: FC<Props> = ({ message }) => (
  <>
    <WarningIcon />
    <Typography variant="body2">
      {message.title}&nbsp;{message.body}
    </Typography>
  </>
)

export default InfoBanner
