import React, { useRef, useCallback } from 'react'
import { useListener } from 'hooks/useListener'
import { useUpdatingRef } from 'hooks/useUpdatingRef'
import { queryThenMutateDOM } from 'utils/queryThenMutateDOM'

type ResizerProps = {
  updateColumnWidth: (val: number) => void
}

const GridTableColumnResizerImpl = React.memo((props: ResizerProps) => {
  const dragState = useRef({
    dragging: false,
    startX: 0,
    cellWidth: 0,
  })
  const propsRef = useUpdatingRef<ResizerProps>(props)
  const onMouseDown = useCallback(ev => {
    ev.stopPropagation()
    ev.persist()
    dragState.current.dragging = true
    document.body.addEventListener('mousemove', mouseMoveListener)
    queryThenMutateDOM(() => {
      dragState.current.cellWidth = ev.target.parentNode.offsetWidth
      dragState.current.startX = ev.clientX
    }, null)
  }, [])
  const mouseMoveListener = useCallback(
    ev => {
      if (dragState.current.dragging) {
        queryThenMutateDOM(
          () => {
            return ev.clientX
          },
          clientX => {
            const movement = clientX - dragState.current.startX
            const props = propsRef.current
            const newWidth = movement + dragState.current.cellWidth
            if (newWidth > 20) {
              props.updateColumnWidth(newWidth)
            }
          },
        )
      }
    },
    [propsRef],
  )

  useListener(
    document.body,
    'mouseup',
    useCallback(() => {
      if (dragState.current.dragging) {
        dragState.current.dragging = false
        document.body.removeEventListener('mousemove', mouseMoveListener)
      }
    }, []),
  )
  return <div className="grid-column-separator" onMouseDown={onMouseDown} />
})

export const GridTableColumnResizer = React.memo(GridTableColumnResizerImpl)
