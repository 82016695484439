import React from 'react'
import App, { AppType } from 'next/app'
import { GalaxyApp } from '@design-library/GalaxyApp'

function getGalaxyApp(appHeader: React.ReactElement): AppType {
  const GalaxyAppWithHeader: AppType = customAppProps => (
    <GalaxyApp {...customAppProps} appHeader={appHeader} />
  )

  GalaxyAppWithHeader.getInitialProps = App.getInitialProps

  return GalaxyAppWithHeader
}

export default getGalaxyApp
