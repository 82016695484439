import { ExposureItem } from 'redux/models'

export enum PortfolioMode {
  Portfolio = 0,
  Analysis = 1,
}

export type PortfolioModel = {
  data: Record<
    number,
    {
      exposures: Record<string, ExposureItem>
      pnl: Record<string, PNLResult>
      pnlPrevDay: Record<string, PNLResult>
      pnlAnalsysis?: Record<string, PNLResult>
      pnlDaily: Record<string, PNLResult>
    }
  >
  analysisToDate: Date
  mode: PortfolioMode
  hideBalances: boolean
  hideBalancesValue: number
  excludeOTC: boolean
  asssetFilter: string
  activeTradingViewCharts: {
    symbol: string
    id: string
  }[]
  eodPrices: Record<string, EODPrices>
  selectedPortfolio: string
  includeNonCrypto: boolean
  pnlReportDialogData: PNLResult[]

  // key is the strategy ID
  derivativeData: Record<string, DerivativePayload>

  version: number
}

export type EODPrices = {
  id: number
  securityId: number
  symbol: string
  price: number
  lastQuote: number
}

export type PNLResult = {
  strategyId: number
  securityId: number
  symbol: string
  realizedPnlForSecurity: number
  realizedPnlAcrossAllSecurities: number
  unrealizedPnlForSecurity: number
  totalPnlAcrossAllSecurities: number
  unrealizedPnlAcrossAllSecurities: number
  unrealizedPnl24hForSecurity: number

  currentPosition: number
  position: number

  currentNav: number
  nav: number
  costBasis: number
  currentPrice: number
  price: number
}

export type DerivativePayload = Record<string, DerivativeContractData>

export type DerivativeContractData = {
  $Delta: number
  $Gamma: number
  $Theta: number
  $Vega: number
  'Avg Price': number
  CP: Cp
  'Delta %': number
  Exp: string
  Flag: Flag
  Fwd: number
  'Implied Vol': number
  Inst: string
  Pos_value: number
  Price: number
  QTY: number
  Spot: number
  Strike: number
  'Total P&L': number
  Type: Type
  Underlying: Underlying
  'Underlying Base': UnderlyingBase
}

enum Cp {
  Gdlp = 'GDLP',
}

enum Flag {
  Call = 'Call',
  Put = 'Put',
}

enum Type {
  Option = 'Option',
}

enum Underlying {
  BtcUsd = 'BTC/USD',
  EthUsd = 'ETH/USD',
}

enum UnderlyingBase {
  Btc = 'BTC',
  Eth = 'ETH',
}
