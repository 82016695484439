import deepEqual from 'react-fast-compare'
import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/portfolioRisk'
import { PortfolioRiskModel } from 'redux/models'
import { registerSaver } from 'redux/store/utils'
import { REDUX_INITIAL_SET } from '../store/localStorage'
import { RootState } from './state'

export const initialState: PortfolioRiskModel = {
  settings: {
    shock: {
      increment: 0.025,
      vlb: -0.2,
      vub: 0.2,
      slb: -0.2,
      sub: 0.2,
    },
    lending: {
      loan_type: 'USD',
      coll_type: 'BTC',
      liq_period: 1,
      tolerance: 0.01,
      data_period: 3,
    },
    beta: {
      scale: 'log',
      days: 30,
      visible: { 'ETH/USD': true },
    },
    volatility: {
      scale: 'log',
      days: 30,
      visible: { 'ETH/USD': true },
    },
    strike: {
      visible: {},
    },
    expiry: {
      visible: {},
    },
  },
  shockData: {
    columns: [],
    rows: [],
    shocks: [],
    xdelta: [],
    gamma: [],
    vega: [],
    labels: {},
    theta: [],
  },
  lendingData: undefined,
  loansVariance: undefined,
  loansVarianceSettings: {},
  volatilitySurfaces: {},
  betaData: {},
  volatilityData: {},
  strikeRollupData: {},
  expiryRollupData: {},
}

registerSaver(state => {
  const s = state.PortfolioRisk
  return {
    PortfolioRisk: {
      settings: s.settings,
    },
  }
})

export const PortfolioRiskReducer = handleActions<PortfolioRiskModel, any>(
  {
    [REDUX_INITIAL_SET]: (state, { payload }) => {
      return produce(state, draft => {
        const persistedState =
          (payload as RootState).PortfolioRisk || ({} as PortfolioRiskModel)
        persistedState.settings =
          persistedState.settings || ({} as PortfolioRiskModel['settings'])
        Object.keys(persistedState.settings).forEach(key => {
          if (!state.settings[key]) {
            draft.settings[key] = persistedState.settings[key]
            return
          }
          Object.assign(draft.settings[key], persistedState.settings[key])
        })
      })
    },
    [Type.SET_SHOCK_SETTINGS]: (state: PortfolioRiskModel, { payload }) => {
      return produce(state, draft => {
        const { volRange, spotRange, increment } = payload
        if (volRange) {
          draft.settings.shock.vlb = -volRange
          draft.settings.shock.vub = volRange
        }
        if (spotRange) {
          draft.settings.shock.slb = -spotRange
          draft.settings.shock.sub = spotRange
        }
        if (increment) {
          draft.settings.shock.increment = increment
        }
      })
    },
    [Type.SET_VOL_SURFACE]: (state: PortfolioRiskModel, { payload }) => {
      return produce(state, draft => {
        draft.volatilitySurfaces[payload.key] = payload.data
      })
    },
    [Type.TOGGLE_LOAN_AGG]: (state: PortfolioRiskModel, { payload }) => {
      return produce(state, draft => {
        draft.loansVarianceSettings[payload] = draft.loansVarianceSettings[
          payload
        ] || { skipAggregate: false }
        draft.loansVarianceSettings[payload].skipAggregate =
          !draft.loansVarianceSettings[payload].skipAggregate
      })
    },
    [Type.TOGGLE_ALL_LOAN_AGG]: (state: PortfolioRiskModel, { payload }) => {
      return produce(state, draft => {
        Object.values(draft.loansVariance || {}).forEach(val => {
          draft.loansVarianceSettings[val.id] = { skipAggregate: payload }
        })
      })
    },
    [Type.SET_DATA]: (state: PortfolioRiskModel, { payload }) => {
      const { data, key } = payload
      return produce(state, draft => {
        if (!deepEqual(state[key], data)) {
          draft[key] = data
        }
      })
    },
    [Type.SET_SETTINGS]: (state: PortfolioRiskModel, { payload }) => {
      return produce(state, draft => {
        const { data, key } = payload
        if (!state.settings[key]) {
          draft.settings[key] = data
          return
        }
        Object.keys(data).forEach(dataKey => {
          if (!deepEqual(state.settings[key][dataKey], payload[key])) {
            draft.settings[key][dataKey] = data[dataKey]
          }
        })
      })
    },
  },
  initialState,
)
