import { FC, PropsWithChildren } from 'react'
import { GalaxyAppHead } from './components/GalaxyAppHead'

export const GalaxyAppDocument: FC<PropsWithChildren> = ({ children }) => (
  <>
    <GalaxyAppHead />
    <noscript>You need to enable JavaScript to run this app.</noscript>
    <div id="root" suppressHydrationWarning>
      {children}
    </div>
  </>
)
