import styled from 'styled-components'

export const Icon = styled.i<IconProps>`
  &:before {
    display: inline-block;
    height: ${({ size }) => size}px;
    aspect-ratio: 1;
    content: '';
    mask: url(${({ svg }) => svg});
    mask-size: contain;
    mask-repeat: no-repeat;
    background-color: ${({ theme, color }) => color ?? theme.colors.white.$01};
  }
`
interface IconProps {
  svg: string
  size: number
  color?: string
}
