export enum STRATEGY {
  GDLP = 14,
  GDLPSS = 69,
  GDLPGT = 78,
  GDLPRV = 71,
  GDLPTT = 72,
  GDT_OTC = 17,
  LUKA = 29,
  BHAR = 31,
  BHAR_DERIVATIVE = 'BHAR_DERIVATIVE',
  BHAR_ALL = 'BHAR_ALL',
  TREY = 32,
  TREY_DERIVATIVE = 'TREY_DERIVATIVE',
  TREY_ALL = 'TREY_ALL',
  VENTURE = 39,
  GOTC = 24,
  OTC = 25,
  DERIVATIVES = 26,
  SPST = 45,
  IFAN = 67,
  JCARR = 66,
  KGUAN = 64,
  REQUIR = 65,
  RMAK = 68,
  GDPS = 50,
  SPST_DERIVATIVE = 'SPST_DERIVATIVE',
  SPST_ALL = 'SPST_ALL',
  GDLP_DERIVATIVE = 'GDLP_DERIVATIVE',
  GDLP_ALL = 'GDLP_ALL',
}
