import { FC, ReactNode, useEffect } from 'react'
import { Button, GalaxyOneProvider, Typography } from '@g1/components'
import Image from 'next/image'
import { getOktaUrl } from './getOktaUrl'

import Styles from './SystemError.module.css'

export type SystemErrorProps = {
  error?: Error
  title: string
  message: ReactNode
  showReachToSupport?: boolean
}

export const SystemError: FC<SystemErrorProps> = ({
  error,
  title,
  message,
  showReachToSupport,
}) => {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <GalaxyOneProvider theme="dark">
      <div className={Styles.SystemErrorPage}>
        <div className={Styles.MainNavigation}>
          <Image
            src="/assets/galaxy-logo.white.svg"
            alt="Galaxy Logo"
            width={140}
            height={40}
          />
        </div>
        <div className={Styles.MainContainer}>
          <div className={Styles.ModalBox}>
            <div className={Styles.ModalBoxContent}>
              <Image
                src="/assets/lock-icon.svg"
                alt="Lock Icon"
                width={68.5}
                height={96}
              />
              <div className={Styles.ModalBoxContentText}>
                <Typography className={Styles.Title} variant="subtitle2">
                  {title}
                </Typography>
                <Typography className={Styles.Message} variant="body2">
                  {message}
                </Typography>
                {showReachToSupport && (
                  <Typography className={Styles.Message} variant="body2">
                    If you believe you have been blocked in error, please reach
                    out to Client Support at{' '}
                    <a href="mailto:galaxyone@galaxy.com">
                      galaxyone@galaxy.com
                    </a>
                  </Typography>
                )}
              </div>
            </div>
            <div className={Styles.ModalBoxButtonGroup}>
              <Button variant="outlined" href={getOktaUrl()}>
                Back To Okta
              </Button>
              <Button variant="outlined" href={`${getOktaUrl()}/login/signout`}>
                Sign Me Out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </GalaxyOneProvider>
  )
}
