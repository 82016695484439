import { toast, ToastContent } from 'react-toastify'

const defaultMessage =
  'Something went wrong. Please contact us if the problem persists.'

const errorToast = (content?: ToastContent) => {
  toast.error(content || defaultMessage)
}

export default errorToast
