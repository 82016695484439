import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { usePrevious } from 'hooks/usePrevious'
import { selectIsUserAuthenicated } from 'redux/selectors/selectIsUserAuthenicated'
import { selectUserFirstName } from 'redux/selectors/selectUserFirstName'
import { selectUserName } from 'redux/selectors/selectUserName'
import CSSCore from 'utils/CSSCore'
import { isG1 } from 'utils/envUtils'
import G1Header from '../G1Header'
import ClientGalaxyNavigation from './ClientGalaxyNavigation'

const ClientGalaxyNavigationContainer: FC = () => {
  const { pathname } = useRouter()
  const isAuthenticated = useSelector(selectIsUserAuthenicated)
  const userName = useSelector(selectUserName)
  const firstName = useSelector(selectUserFirstName)

  const activeItem = pathname.toLowerCase()
  const previousActiveItem = usePrevious(activeItem).current

  const AppNavigation = isG1() ? G1Header : ClientGalaxyNavigation

  toggleAppClass(activeItem, previousActiveItem)

  return (
    <AppNavigation
      user={isG1() ? firstName : userName}
      activeItem={activeItem}
      loggedIntoOkta={isAuthenticated}
    />
  )
}

function toggleAppClass(activeItem: string, previousActiveItem: string): void {
  if (activeItem === previousActiveItem) {
    return
  }

  if (previousActiveItem) {
    const prevClass = '_' + previousActiveItem.replace(/\d/g, '').split('/')[1]
    CSSCore.removeClass(document.body, prevClass)
  }

  const nextClass = '_' + activeItem.replace(/\d/g, '').split('/')[1]
  CSSCore.addClass(document.body, nextClass)
}

export default React.memo(ClientGalaxyNavigationContainer)
