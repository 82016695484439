import { produce } from 'immer'
import moment from 'moment'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/derivativePricing'
import {
  DerivativePricingModel,
  BASE_CURRENCIES,
  QUOTE_CURRENCIES,
} from 'redux/models'
import { registerSaver } from 'redux/store/utils'
import { addMonth } from 'utils/moment-util'
import { REDUX_INITIAL_SET } from '../store/localStorage'
import { RootState } from './state'

const initialState: DerivativePricingModel = {
  legs: [],
  base: BASE_CURRENCIES[0].value,
  quote: QUOTE_CURRENCIES[0].value,
  valuationDate: addMonth(moment()),
  showInUnderlying: false,
  solve: 'Premium',
  solveLeg: 0,
}

registerSaver(() => {
  return {}
})

export const DerivativePricingReducer = handleActions<
  DerivativePricingModel,
  any
>(
  {
    [REDUX_INITIAL_SET]: (state, { payload }) => {
      return produce(state, () => {
        const persistedState =
          (payload as RootState).DerivativePricing ||
          ({} as DerivativePricingModel)
      })
    },
    [Type.ADD_LEG]: (state: DerivativePricingModel) => {
      return produce(state, draft => {
        draft.legs.push({
          trade: 'Buy',
          type: 'Call',
          expiration: addMonth(moment()),
          spotReference: 10000,
          forwardRate: 0.39,
          volatility: 0.2,
          strike: 10000,
          notional: 1,
          mult: 1,
          premium: 1,
          refreshSpot: true,
        })
      })
    },
    [Type.REMOVE_LEG]: (state: DerivativePricingModel, { payload }) => {
      return produce(state, draft => {
        draft.legs.splice(payload.index, 1)
        draft.solveLeg = Math.min(
          draft.solveLeg,
          Math.max(draft.legs.length - 1, 0),
        )
      })
    },
    [Type.UPDATE_LEG]: (state: DerivativePricingModel, { payload }) => {
      return produce(state, draft => {
        draft.legs[payload.index] = payload.leg
      })
    },
    [Type.CLEAR_LEGS]: (state: DerivativePricingModel) => {
      return produce(state, draft => {
        draft.legs = []
      })
    },
    [Type.SET_FIELD]: (state: DerivativePricingModel, { payload }) => {
      return produce(state, draft => {
        draft[payload.field] = payload.value
      })
    },
    [Type.SET_RESULTS]: (state: DerivativePricingModel, { payload }) => {
      return produce(state, draft => {
        draft.results = payload
      })
    },
  },
  initialState,
)
