import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react'
import type { ColDef, CsvExportParams, GridApi } from 'ag-grid-community'
import { LicenseManager } from 'ag-grid-enterprise'
import type { AgGridReactProps } from 'ag-grid-react'
import { isEqual } from 'lodash'
import dynamic from 'next/dynamic'

import { usePrevious } from 'hooks/usePrevious'
import { useSelectorPerformant } from 'hooks/useSelectorPerformant'
import { useStateRef } from 'hooks/useStateRef'
import { Type } from 'redux/actions/AgGridTables'

import { RootState } from 'redux/reducers/state'
import { areArraysEqual } from 'utils/areArraysEqual'
import { dispatch } from 'utils/dispatch'
import { isClientPortal } from 'utils/isClientPortal'

import { dateFormatterCustom, GalaxyGridProp } from './GalaxyGridHelper'

const areColumnIdsEqual = (colState: ColDef[], colDefs: ColDef[]): boolean =>
  isEqual(
    colState
      .filter(({ colId }) => colId !== 'ag-Grid-AutoColumn')
      .map(({ colId }) => colId)
      .sort(),
    colDefs.map(({ colId }) => colId).sort(),
  )

const DEFAULT_FILTER_PARAMS: ColDef['filterParams'] = {
  buttons: ['clear'],
  debounceMs: 1000,
}

const csvExportParams: CsvExportParams = {
  processCellCallback: params => {
    if (params.column?.getColDef()?.filter === 'agDateColumnFilter') {
      return dateFormatterCustom('M/D h:mm:ss.SSS a')(params)
    }
    return params.value
  },
}

LicenseManager.setLicenseKey(
  'CompanyName=SHI International Corp._on_behalf_of_Galaxy Digital (USA, New York, NY, 10282) (HQ),LicensedGroup=Galaxy,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=12,LicensedProductionInstancesCount=2,AssetReference=AG-038496,SupportServicesEnd=15_March_2024_[v2]_MTcxMDQ2MDgwMDAwMA==6b0af3804dc515491da50815d8c75e89',
)

const AgGridReact = dynamic<AgGridReactProps>(
  () => import('ag-grid-react').then(mod => mod.AgGridReact),
  { ssr: false },
)

const GalaxyGrid: FunctionComponent<GalaxyGridProp> = props => {
  const {
    className,
    columnDefs: columnsDefinitions,
    controlsLoading = false,
    dark = isClientPortal,
    header,
    id,
    height = '100%',
    width = '100%',
    isInfinite = false,
    isLoading = false,
    rowData,
    getGridApi,
    getColumnApi,
    style,
    version = -1,
    onColumnResized: _onColumnResized,
    onFilterChanged: _onFilterChanged,
    onGridReady,
    onRowDataUpdated,
    optionalElement = () => null,
  } = props
  const theme = dark ? 'ag-theme-balham-dark' : 'ag-theme-balham'

  const columnDefs = useMemo(() => {
    return columnsDefinitions.map(def => {
      return {
        colId: (def as ColDef).field || def.headerName,
        filterParams: DEFAULT_FILTER_PARAMS,
        ...def,
      }
    })
  }, [columnsDefinitions])

  const [gridApiRef, setGridApiRef] = useStateRef<GridApi>()
  const gridApi = gridApiRef.current

  const onGridReadyCB = useCallback<AgGridReactProps['onGridReady']>(
    event => {
      getColumnApi?.(event.columnApi)
      onGridReady?.(event)
      setGridApiRef(event.api)
      getGridApi?.(event.api)
    },
    [getColumnApi, onGridReady, getGridApi, setGridApiRef],
  )

  const onLoadingDone = useCallback<VoidFunction>(() => {
    if (
      controlsLoading ||
      !gridApi ||
      gridApi.getModel().getType() === 'serverSide'
    ) {
      return
    }

    if (isLoading) {
      gridApi.showLoadingOverlay()
    } else if (!isInfinite && (!rowData || !rowData.length)) {
      gridApi.showNoRowsOverlay()
    } else {
      gridApi.hideOverlay()
    }
  }, [isLoading, gridApi, isInfinite, rowData, controlsLoading])

  useLayoutEffect(onLoadingDone, [onLoadingDone])
  const prevRowData = usePrevious(rowData).current
  const prevLoading = usePrevious(isLoading).current
  if (prevLoading && !isLoading) {
    if (areArraysEqual(rowData, prevRowData, isEqual)) {
      onLoadingDone()
    }
  }

  const onRowDataUpdatedCB = useCallback<AgGridReactProps['onRowDataUpdated']>(
    event => {
      onRowDataUpdated?.(event)
      onLoadingDone()
    },
    [onRowDataUpdated, onLoadingDone],
  )

  const columnState = useSelectorPerformant<RootState, ColDef[] | undefined>(
    rootState => (id ? rootState.AgGridTables[id] : undefined),
  )
  const defs = useMemo(() => {
    if (!columnState || !areColumnIdsEqual(columnState, columnDefs)) {
      return columnDefs
    }
    const columnsMap = columnState.reduce((acc, def, index) => {
      acc[def.colId] = {
        index,
        width: def.width,
        hide: def.hide,
        flex: def.flex,
      }
      return acc
    }, {})

    columnDefs.forEach((def: ColDef) => {
      if (columnsMap[def.colId]) {
        def.width = columnsMap[def.colId].width
        def.hide = columnsMap[def.colId].hide
        def.flex = columnsMap[def.colId].flex
      }
    })
    return columnDefs.sort((a, b) => {
      if (columnsMap[a.colId] && columnsMap[b.colId]) {
        return columnsMap[a.colId].index - columnsMap[b.colId].index
      }
      if (columnsMap[a.colId]) {
        return -1
      }
      if (columnsMap[b.colId]) {
        return 1
      }
      return 0
    })
  }, [columnState, columnDefs])

  const persistState = useCallback<
    AgGridReactProps['onColumnEverythingChanged']
  >(
    ({ columnApi }) => {
      if (id) {
        dispatch({
          type: Type.SET_COLUMN_DEFS,
          payload: {
            id,
            defs: columnApi.getColumnState(),
            version,
          },
        })
      }
    },
    [id, version],
  )

  const onColumnResized = useCallback<AgGridReactProps['onColumnResized']>(
    event => {
      persistState(event)
      _onColumnResized?.(event)
    },
    [persistState, _onColumnResized],
  )

  const onFilterChangedCB = useCallback<AgGridReactProps['onFilterChanged']>(
    event => {
      const { api } = event
      if (api.getDisplayedRowCount() === 0) {
        api.showNoRowsOverlay()
      } else {
        api.hideOverlay()
      }
      _onFilterChanged?.(event)
    },
    [_onFilterChanged],
  )

  const optional = header && optionalElement()
  return (
    <div
      className={`${theme} ${className || ''}`}
      style={{ ...style, height, width }}
    >
      {header && (
        <h3 className="ui header relative">
          {header}
          {optional ? <span>{optional}</span> : null}
        </h3>
      )}
      <AgGridReact
        {...props}
        columnDefs={defs}
        maintainColumnOrder
        onColumnMoved={persistState}
        onColumnResized={onColumnResized}
        onColumnEverythingChanged={persistState}
        onGridReady={onGridReadyCB}
        onRowDataUpdated={onRowDataUpdatedCB}
        onFilterChanged={onFilterChangedCB}
        defaultCsvExportParams={props.defaultCsvExportParams || csvExportParams}
        overlayNoRowsTemplate='<span style="margin-top: 30px">No results for filter</span>'
      />
    </div>
  )
}

export default GalaxyGrid
