import { produce } from 'immer'
import { handleActions } from 'redux-actions'
import { Type } from 'redux/actions/securities'
import {
  SecurityData,
  SecurityData2,
  SecuritiesModel,
  AssetStoreSecurityAlias,
} from 'redux/models'

const initialState: SecuritiesModel = {
  byDisplayName: {},
  byID: {},
  prices: {},
}

export const SecuritiesReducer = handleActions<SecuritiesModel, any>(
  {
    [Type.SECURITIES_DATA]: (
      state: SecuritiesModel,
      { payload }: { payload: SecurityData[] },
    ) => {
      return produce(state, draft => {
        payload.forEach(pair => {
          draft.byDisplayName[pair.displaySymbol] = {
            ...pair,
          }
          draft.byID[pair.id] = {
            ...pair,
          }
        })
      })
    },
    [Type.SECURITIES_PRICES]: (
      state: SecuritiesModel,
      { payload }: { payload: SecurityData2[] },
    ) => {
      return produce(state, draft => {
        payload.forEach(info => {
          draft.prices[info.symbol] = info
        })
      })
    },
    [Type.POPULATE_SECURITY_ALIAS_PRECISION]: (
      state: SecuritiesModel,
      { payload }: { payload: AssetStoreSecurityAlias[] },
    ) => {
      return produce(state, draft => {
        payload.filter(Boolean).forEach(alias => {
          if (draft.byDisplayName[alias.displaySymbol])
            draft.byDisplayName[alias.displaySymbol].precisionPrice =
              alias.precisionPrice

          if (draft.byID[alias.securityId])
            draft.byID[alias.securityId].precisionPrice = alias.precisionPrice
        })
      })
    },
  },
  initialState,
)
