import moment from 'moment'
import { mapToOpt } from 'utils/dropdown-util'

export type DerivativePricingModel = {
  legs: Array<Leg>
  base?: string
  quote?: string
  valuationDate?: any
  results?: DerivativePricingResults
  solve: 'Strike' | 'Premium' | 'Volatility'
  solveLeg: number
  showInUnderlying: boolean
}

export type Leg = {
  trade: 'Buy' | 'Sell'
  type: 'Call' | 'Put'
  expiration: any
  spotReference: number
  forwardRate: number
  strike: number
  volatility: number
  notional: number // qty
  mult: number
  premium: number
  refreshSpot: boolean
}

export const BASE_CURRENCIES = ['BTC', 'BCH', 'ETH'].map(text => mapToOpt(text))
export const QUOTE_CURRENCIES = ['USD'].map(text => mapToOpt(text))

export interface ContractsPayload {
  Columns: number[]
  Contracts: Contracts
  Rows: number[]
}

export type Contracts = Record<string, Contract>

export interface Contract {
  Price: number

  $Delta: number
  $Gamma: number
  $Theta: number
  $Vega: number

  Delta: Array<number[]>
  Gamma: Array<number[]>
  Shock: Array<number[]>
  Theta: Array<number[]>
  Vega: Array<number[]>
}

export type ContractArgs = {
  pair: string
  flag: 'C' | 'P'
  QTY: number
  Mult: number
  strike: number | 'X'
  expiry: string // 2020-09-09
  vol: number | 'X'
  model: 'BSM'
  premium: number | 'X'
  spot: number
  set: 'PRE' | 'STK' | 'VOL'
  refresh: boolean
}

export type DerivativePricingResults = {
  legs: LegResult[]
  params: ContractArgs[]
  ts: ReturnType<typeof moment>
}

export type LegResult = {
  Individual: IndividualResult
  Notional: NotionalResult
  Properties: PropertiesResult
}

export type IndividualResult = {
  Delta: number
  Gamma: number
  'Price per contract': number
  Theta: number
  Vega: number
}

export type NotionalResult = {
  $Delta: number
  $Gamma: number
  $Theta: number
  $Vega: number
  'Price total': number
}

export type PropertiesResult = {
  Expiry: Date
  Flag: string
  Model: string
  Mult: string
  Pair: string
  QTY: string
  Rate: number
  Strike: string
  Vol: string
  Spot: number
  Setting: string
}
