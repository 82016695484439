import type { Reducer, ReducersMapObject } from 'redux'
import { isBrowser } from 'utils/isBrowser'
import { AdminPanelReducer } from './admin'
import { AgGridTablesReducer } from './aggrid'
import { CounterpartyEntitiesReducer } from './counterpartyEntities'
import { DailyBlotterReducer } from './dailyBlotter'
import { DepositAddressReducer } from './depositAddress'
import { DerivativePricingReducer } from './derivativePricing'
import { GalaxyUserReducer } from './galaxyuser'
import { GridTablesReducer } from './gridtable'
import { HistoricalBlotterReducer } from './historicalBlotter'
import { LiveRiskReducer } from './liveRisk'
import { MonitorReducer } from './monitor'
import { OktaUserReducer } from './oktauser'
import { PortfolioReducer } from './portfolio'
import { PortfolioRiskReducer } from './portfolioRisk'
import { SecuritiesReducer } from './securities'
import { settlementReducer } from './settlements'
import type { RootState } from './state'
import { TransfersReducer } from './transfers'

const REDUCERS: ReducersMapObject<RootState> = {
  settlement: settlementReducer,
  OktaUser: OktaUserReducer,
  GalaxyUser: GalaxyUserReducer,
  DailyBlotter: DailyBlotterReducer,
  HistoricalBlotter: HistoricalBlotterReducer,
  LiveRisk: LiveRiskReducer,
  Securities: SecuritiesReducer,
  Admin: AdminPanelReducer,
  Transfers: TransfersReducer,
  PortfolioRisk: PortfolioRiskReducer,
  AgGridTables: AgGridTablesReducer,
  Monitor: MonitorReducer,
  GridTables: GridTablesReducer,
  DerivativePricing: DerivativePricingReducer,
  Portfolio: PortfolioReducer,
  DepositAddress: DepositAddressReducer,
  CounterpartyEntitiesList: CounterpartyEntitiesReducer,
}

export const rootReducer: Reducer<RootState> = function combinedReducers(
  state,
  action,
) {
  const newState = {} as RootState
  updateGlobalState(state)
  Object.keys(REDUCERS).forEach(rKey => {
    if (REDUCERS[rKey]) {
      newState[rKey] = REDUCERS[rKey](state ? state[rKey] : undefined, action)
    } else {
      newState[rKey] = state ? state[rKey] : undefined
    }
  })
  updateGlobalState(newState)
  return newState
}

function updateGlobalState(state: RootState): void {
  if (isBrowser) {
    window._GLOBAL_STATE = state
  }
}
