import React, { useCallback, useMemo } from 'react'
import Datetime from 'react-datetime'
import cx from 'classnames'
import moment from 'moment-timezone'
import { Button, Dropdown } from 'semantic-ui-react'
import {
  currencyFormatter,
  formatNumber2,
} from 'components/Galaxy/GalaxyGridHelper'
import type { ColumnConfig } from 'components/GridTable'
import {
  useGridTable,
  numberFilter,
  numberSorter,
  stringSorter,
  setFilter,
  SORTDIR,
} from 'components/GridTable'
import Modal from 'components/Modal'
import { getPNLData } from 'data/pnl'
import useAuthUser from 'hooks/useAuthUser'
import { useEffectNow } from 'hooks/useEffectNow'
import { useSelectorPerformant } from 'hooks/useSelectorPerformant'
import { useState } from 'hooks/useState'
import { useStateRef } from 'hooks/useStateRef'
import { Type } from 'redux/actions/portfolio'
import { Strategy } from 'redux/models'
import { RootState } from 'redux/reducers/state'
import getSelectedStrategyID from 'redux/selectors/getSelectedStrategyID'
import { dispatch } from 'utils/dispatch'
import { mapToOpt } from 'utils/dropdown-util'
import { emptyArray } from 'utils/emptyArray'
import { getState } from 'utils/getState'
import Styles from './styles.module.scss'

type Props = {
  onClose: Function
  open: boolean
}

function getDefaultDate(daysAgoBefore5pm) {
  let days = daysAgoBefore5pm
  if (moment().tz('America/New_York').hour() < 17) {
    days += 1
  }
  return moment()
    .tz('America/New_York')
    .set({
      hour: 17, // 5pm
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .subtract(days, 'days')
    .toDate()
}

const PnLReportDialog = React.memo((props: Props) => {
  const [selectedStrategyRef, setSelectedStrategy] = useStateRef(
    getSelectedStrategyID(getState()),
  )
  const { strategies = emptyArray } = useAuthUser('internal')
  const [fromDateRef, setFromDate] = useStateRef<Date | undefined>()
  const [toDateRef, setToDate] = useStateRef<Date>()
  useEffectNow(() => {
    if (![17, 24, 25].includes(selectedStrategyRef.current)) {
      fromDateRef.current = undefined
      toDateRef.current = undefined
      return
    }
    fromDateRef.current = getDefaultDate(1)
    toDateRef.current = getDefaultDate(0)
  }, [selectedStrategyRef.current])

  const [isLoading, setIsLoading] = useState(true)

  const fetchData = useCallback(() => {
    const strategyId = selectedStrategyRef.current
    const fromDate = fromDateRef.current
    const toDate = toDateRef.current

    setIsLoading(true)
    getPNLData({ strategyId, fromDate, toDate }, data => {
      dispatch({
        type: Type.SET_PNL_REPORT_DATA,
        payload: {
          data,
        },
      })
      setIsLoading(false)
    })
  }, [])

  useEffectNow(fetchData, [selectedStrategyRef.current])

  const rowData = useSelectorPerformant<
    RootState,
    RootState['Portfolio']['pnlReportDialogData']
  >(rootState => rootState.Portfolio.pnlReportDialogData)

  const { table } = useGridTable({
    id: 'PnLReportDialog',
    getRowKey: row => row.symbol,
    columns: COLUMNS,
    rowRenderer,
    rowData: rowData,
    isLoading,
    version: 2,
  })

  const { toDateMoment, fromDateMoment } = useMemo(() => {
    const fromDateMoment = fromDateRef.current
      ? moment(fromDateRef.current)
      : null
    const toDateMoment = toDateRef.current ? moment(toDateRef.current) : null
    return {
      fromDateMoment,
      toDateMoment,
    }
  }, [fromDateRef.current, toDateRef.current])

  const setFromDateCB = useCallback(arg => {
    setFromDate(arg && arg.toDate ? arg.toDate() : null)
  }, [])

  const setToDateCB = useCallback(arg => {
    setToDate(arg && arg.toDate ? arg.toDate() : null)
  }, [])

  const availableStrategies = useMemo(() => {
    const strats: Strategy[] = []
    strategies.forEach(strat => {
      strats.push(strat)
      if (strat.subStrategies) {
        strat.subStrategies.forEach(strat => {
          strats.push(strat)
        })
      }
    })
    return strats.map(strat => mapToOpt(strat.name, strat.id))
  }, [strategies])

  const results = useMemo(() => {
    const d = rowData[0]
    if (d) {
      return (
        <div className={Styles.resultsWrapper}>
          <div>
            <div className={Styles.resultLabel}>Total Pnl:</div>
            <ColoredResult value={d.totalPnlAcrossAllSecurities} />
          </div>
          <div>
            <div className={Styles.resultLabel}>Total Realized Pnl:</div>
            <ColoredResult value={d.realizedPnlAcrossAllSecurities} />
          </div>
          <div>
            <div className={Styles.resultLabel}>Total Unrealized Pnl:</div>
            <ColoredResult value={d.unrealizedPnlAcrossAllSecurities} />
          </div>
        </div>
      )
    }
  }, [rowData])

  return (
    <Modal
      header={'PnL Reporting'}
      onClose={props.onClose as any}
      open={props.open}
      size="large"
    >
      <div className={Styles.root}>
        <div className={Styles.inputs}>
          <div>
            <div>Strategy:</div>
            <Dropdown
              value={selectedStrategyRef.current}
              options={availableStrategies}
              onChange={useCallback((_, { value }) => {
                setSelectedStrategy(value as number)
              }, [])}
            />
          </div>
          <div className={Styles.dateInputs}>
            <div>
              From Date:{' '}
              <Datetime
                value={fromDateMoment as any}
                onChange={setFromDateCB}
                closeOnSelect={true}
              />
            </div>
            <div>
              To Date:{' '}
              <Datetime
                value={toDateMoment as any}
                onChange={setToDateCB}
                closeOnSelect={true}
              />
            </div>
            <div className="thin-input">
              <Button primary content={'Submit'} onClick={fetchData} />
            </div>
          </div>
        </div>
        {results}
        <div className={Styles.tableWrapper}>{table}</div>
      </div>
    </Modal>
  )
})

PnLReportDialog.displayName = 'PnLReportDialog'
export default PnLReportDialog

const ColoredResult = React.memo(({ value }: any) => {
  return (
    <span
      className={cx({
        [Styles.positive]: value > 0,
        [Styles.negative]: value < 0,
      })}
    >
      {currencyFormatter({ value })}
    </span>
  )
})

ColoredResult.displayName = 'ColoredResult'

const coloredNumberCellStyle = ({ value }) => {
  let color
  if (value > 0) {
    color = 'green'
  } else if (value < 0) {
    color = 'red'
  }
  return { color, textAlign: 'right' }
}

const COLUMNS: ColumnConfig[] = [
  {
    field: 'symbol',
    filter: setFilter,
    sortAsc: stringSorter,
  },
  {
    headerName: 'Position',
    field: 'position',
    filter: numberFilter,
    sortAsc: numberSorter,
    cellStyle: { textAlign: 'right' },
    valueFormatter: formatNumber2,
    getSortValue: ({ value }) => Math.abs(value),
  },
  {
    headerName: 'Nav',
    field: 'nav',
    filter: numberFilter,
    sortAsc: numberSorter,
    cellStyle: coloredNumberCellStyle,
    valueFormatter: currencyFormatter,
    getSortValue: ({ value }) => Math.abs(value),
  },
  {
    headerName: 'Cost Basis',
    field: 'costBasis',
    filter: numberFilter,
    sortAsc: numberSorter,
    cellStyle: { textAlign: 'right' },
    valueFormatter: currencyFormatter,
  },
  {
    headerName: 'Price',
    field: 'price',
    filter: numberFilter,
    sortAsc: numberSorter,
    cellStyle: { textAlign: 'right' },
    valueFormatter: currencyFormatter,
  },
  {
    headerName: 'Unrealized Pnl',
    field: 'unrealizedPnlForSecurity',
    filter: numberFilter,
    sortAsc: numberSorter,
    cellStyle: coloredNumberCellStyle,
    valueFormatter: currencyFormatter,
    getSortValue: ({ value }) => Math.abs(value),
    sortDir: SORTDIR.DESC,
  },
  {
    headerName: 'Unrealized Pnl 24h',
    field: 'unrealizedPnl24hForSecurity',
    filter: numberFilter,
    sortAsc: numberSorter,
    cellStyle: coloredNumberCellStyle,
    valueFormatter: currencyFormatter,
    getSortValue: ({ value }) => Math.abs(value),
    sortDir: SORTDIR.DESC,
  },
]

const rowRenderer = ({ children }) => {
  return <div>{children}</div>
}
