import React, { FC } from 'react'
import { GalaxyOneProvider } from '@g1/components'
import cx from 'classnames'
import { useMaintenanceIntervals } from '../hooks/useMaintenanceIntervals'
import InfoBanner from './components/InfoBanner'
import WarningBanner from './components/WarningBanner'

import Styles from './styles.module.scss'

const G1Banner: FC = () => {
  const { message, showBeforeMaintenanceBanner, showMaintenanceBanner } =
    useMaintenanceIntervals()

  if (!showBeforeMaintenanceBanner && !showMaintenanceBanner) {
    return null
  }

  const MaintenanceBanner = showBeforeMaintenanceBanner
    ? InfoBanner
    : WarningBanner

  return (
    <GalaxyOneProvider theme="dark">
      <div className={Styles.container}>
        <div
          className={cx({
            [Styles.banner]: true,
            [Styles.warning]: showMaintenanceBanner,
          })}
        >
          <MaintenanceBanner message={message} />
        </div>
      </div>
    </GalaxyOneProvider>
  )
}

export default React.memo(G1Banner)
