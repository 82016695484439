import qs from 'qs'
import { get as getGK } from 'Gatekeeper'
import { Type } from 'redux/actions/settlements'
import getSelectedStrategyID from 'redux/selectors/getSelectedStrategyID'
import { getKeyedDispatchQueue } from 'utils/dispatch'
import emptyFunction from 'utils/emptyFunction'
import { getState } from 'utils/getState'
import { delete_, create, get } from 'utils/http'
import { isClientPortal } from 'utils/isClientPortal'
import { getSecuritiesPrices } from './securities'
import { CreateSettlementRequestBody } from './types/client/settlement'

const baseUrl = process.env.NEXT_PUBLIC_SETTLEMENT_SERVICE_BASE_URL || ''

const settlementBufferedQueue = getKeyedDispatchQueue()

export function getSettlement(
  id,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  const path = `settlement/historical/${id}`

  return get(
    baseUrl,
    path,
    data => {
      data.orders = data.orders || []
      data.transfers = data.transfers || []
      settlementBufferedQueue(id, {
        type: Type.SET_SETTLEMENT,
        payload: { id, data },
      })
      onSuccess(data)
    },
    onError,
  ).retry()
}

export function deleteSettlement(
  batchId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  let path = `/settlement/historical/batch/${batchId}`
  if (isClientPortal) {
    path = 'settlements/' + path
  }
  return delete_(baseUrl, path, onSuccess, onError)
}

export function deleteSettlementBatch(
  batchId: number | string,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  const path = isClientPortal
    ? `settlements/settlement/historical/batch/${batchId}`
    : `/settlement/historical/batch/${batchId}`

  return delete_(baseUrl, path, onSuccess, onError)
}

const unsettledTradesBufferedQueue = getKeyedDispatchQueue()
export function getUnsettledTrades(
  cpId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
  startDate?,
  endDate?,
) {
  if (isClientPortal) {
    return getClientUnsettledTrades(cpId)
  }

  const strategyId = getSelectedStrategyID(getState())
  const endpoint = 'orders/unsettled'
  const query = qs.stringify({
    accountId: cpId,
    strategyIds: strategyId,
    startDate: Date.parse(startDate) ? startDate : undefined,
    endDate: Date.parse(endDate) ? endDate : undefined,
  })

  return get(
    baseUrl,
    `${endpoint}?${query}`,
    unsettledTradesData => {
      unsettledTradesBufferedQueue(cpId, {
        type: Type.UPDATE_UNSETTLED_TRADES,
        payload: {
          unsettledTradePayload: unsettledTradesData,
          id: cpId,
        },
      })
      fetchSecurities(unsettledTradesData)
      onSuccess(unsettledTradesData)
    },
    err => {
      onError(err)
    },
  ).retry()
}

export function getClientUnsettledTrades(cpId) {
  return get(baseUrl, `trades/unsettled`, unsettledTradesData => {
    unsettledTradesBufferedQueue(cpId, {
      type: Type.UPDATE_UNSETTLED_TRADES,
      payload: {
        unsettledTradePayload: unsettledTradesData,
        id: cpId,
      },
    })
    fetchSecurities(unsettledTradesData)
  }).retry()
}

function fetchSecurities(trades) {
  const allCurrencies = new Set<number>()
  trades.forEach(trade => {
    allCurrencies.add(trade.counterCcySecurityId)
    allCurrencies.add(trade.baseCcySecurityId)
  })
  getSecuritiesPrices(Array.from(allCurrencies))
}

const unallocatedTransfersBufferedQueue = getKeyedDispatchQueue()
export function getUnallocatedTransfers(
  cpId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  if (isClientPortal) {
    return // Not supported
  }

  const strategyId = getSelectedStrategyID(getState())
  const path = `settlement/transfer/unallocated?accountId=${cpId}&strategyIds=${strategyId}`

  return get(
    baseUrl,
    path,
    unallocatedTransfers => {
      unallocatedTransfersBufferedQueue(cpId, {
        type: Type.UPDATE_UNNALLOCATED_TRANSFERS,
        payload: {
          data: unallocatedTransfers,
          id: cpId,
        },
      })
      onSuccess(unallocatedTransfers)
    },
    err => {
      onError(err)
    },
  ).retry()
}

export const fetchSettlements = (onSuccess = emptyFunction) => {
  const selectedStrategyId = getSelectedStrategyID(getState())
  let remaining = 3
  const successCB = onSuccess
    ? () => {
        if (--remaining === 0) {
          onSuccess()
        }
      }
    : null
  const ds1 = fetchOutstandingSettlementsByCounterparty(
    selectedStrategyId,
    successCB,
  )
  const ds2 = fetchOTCInventory(selectedStrategyId, successCB)
  const ds3 = fetchCpsWithFailedAutoSettlementsOrSweeps(successCB)
  return {
    cancel: () => {
      ds1 && ds1.cancel()
      ds2 && ds2.cancel()
      ds3 && ds3.cancel()
    },
  }
}

export const fetchCPSettlements = (onSuccess = emptyFunction) => {
  const successCB = data => {
    outstandingSettlementsByCpBufferedQueue(0, {
      type: Type.SET_OUTSTANDING_SETTLEMENTS_BY_CP,
      payload: data,
    })
    onSuccess && onSuccess()
  }
  const ds = get(baseUrl, 'settlements/outstanding', successCB).retry()

  return {
    cancel: () => {
      ds.cancel()
    },
  }
}

const outstandingSettlementsByCpBufferedQueue = getKeyedDispatchQueue()
export function fetchOutstandingSettlementsByCounterparty(
  strategyId?: string | number,
  onSuccess?: () => void,
) {
  const successCB = data => {
    outstandingSettlementsByCpBufferedQueue(0, {
      type: Type.SET_OUTSTANDING_SETTLEMENTS_BY_CP,
      payload: data,
    })
    onSuccess?.()
  }
  return get(
    baseUrl,
    `settlement/outstandingsetlbycp/strategy/${strategyId}`,
    successCB,
  ).retry()
}

// Backend class name getFailedAutoSettlementsAndSweepsByCp
const cpsWithFailedAutoSettlementsOrSweeps = getKeyedDispatchQueue()
export function fetchCpsWithFailedAutoSettlementsOrSweeps(
  onSuccess?: () => void,
) {
  const isAutoSettlementGkEnabled = getGK('auto_settlement')
  if (!isAutoSettlementGkEnabled) {
    if (onSuccess) {
      onSuccess()
    }

    return null
  }

  const successCB = ({ failedAutoSettlementAndSweepCpIds }) => {
    cpsWithFailedAutoSettlementsOrSweeps(0, {
      type: Type.SET_CPS_WITH_FAILED_AUTO_SETTLEMENTS_OR_SWEEPS,
      payload: failedAutoSettlementAndSweepCpIds,
    })
    if (onSuccess) {
      onSuccess()
    }
  }
  return get(
    baseUrl,
    '/settlement/outstandingsetlbycp/failedAutoSettlementAndSweepCpIds',
    successCB,
  ).retry()
}

const OTCInventoryBufferedQueue = getKeyedDispatchQueue()
export function fetchOTCInventory(
  strategyId?: string | number,
  onSuccess?: VoidFunction,
) {
  return get(
    baseUrl,
    'settlement/galaxyotcinventory/strategy/' + strategyId,
    data => {
      OTCInventoryBufferedQueue(0, {
        type: Type.SET_OTC_INVENTORY,
        payload: data,
      })
      onSuccess && onSuccess()
    },
  ).retry()
}

export function saveTransfer(
  props,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return create(baseUrl, `settlement/transfer/`, props, onSuccess, onError)
}

export function initCreateSettlement(
  data: any,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return create(
    baseUrl,
    'settlement/check/createSettlement',
    data,
    ({ netSettlements, cashSettlements }) => {
      const settlements = netSettlements.concat(cashSettlements)
      settlements.forEach(settlement => {
        settlement.id = [
          settlement.securityId,
          settlement.settlementDirection,
          settlement.settlementType,
        ].join(',')
      })
      onSuccess(settlements)
    },
    onError,
  )
}

export function createSettlement(
  data,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  const strategyId = getSelectedStrategyID(getState())

  return create(
    baseUrl,
    `settlement/createSettlement?strategyIds=${strategyId}`,
    data,
    onSuccess,
    onError,
  )
}

export function netSettleTrades(data, onSuccess) {
  return create(baseUrl, 'settlement/net', data, onSuccess)
}

const cpDataBuffer = getKeyedDispatchQueue()
export function fetchCounterPartydata(
  accountId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  const strategyId = getSelectedStrategyID(getState())
  return get(
    baseUrl,
    `settlement/outstandingsetlbycp/${accountId}/strategy/${strategyId}`,
    data => {
      cpDataBuffer(0, {
        type: Type.SET_OUTSTANDING_SETTLEMENTS_BY_CP,
        payload: data,
      })
      onSuccess(data)
    },
    onError,
  ).retry()
}

const historicalDataBuffer = getKeyedDispatchQueue()
export function fetchHistoricalSettlements(
  accountId,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  if (isClientPortal) {
    return get(
      baseUrl,
      `settlements/settlement/historical`,
      data => {
        historicalDataBuffer(accountId, {
          type: Type.SET_HISTORICAL_SETTLEMENTS,
          payload: data,
          id: accountId,
        })
        onSuccess(data)
      },
      onError,
    ).retry()
  }
  return get(
    baseUrl,
    `settlement/historical?accountId=${accountId}`,
    data => {
      historicalDataBuffer(accountId, {
        type: Type.SET_HISTORICAL_SETTLEMENTS,
        payload: data,
        id: accountId,
      })
      onSuccess(data)
    },
    onError,
  ).retry()
}

export function initSettlementRequest(
  data,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return create(
    baseUrl,
    'settlements/check/requestSettlement',
    data,
    onSuccess,
    onError,
  )
}

export function createSettlementRequest(
  data: CreateSettlementRequestBody,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return create(
    baseUrl,
    'settlements/requestSettlement',
    data,
    onSuccess,
    onError,
  )
}

export function createSettleRequestedSettlement(
  data,
  onSuccess = emptyFunction,
  onError = emptyFunction,
) {
  return create(
    baseUrl,
    'settlement/settleRequestedSettlement',
    data,
    onSuccess,
    onError,
  )
}
