import { AccountInfo } from 'redux/models'

export const platformAccessAccountAttributeTypeId = 33
export const tradingAccountAttributeTypeId = 21
export const marginTradingAccountAttributeTypeId = 32

//lending and borrowing accounts are part of the lending application
export const lendingAccountAttributeTypeId = 6
export const borrowerAccountAttributeTypeId = 7

const isTypeOfAccount =
  (attributeTypeId: number) =>
  (account: AccountInfo): boolean =>
    account.accountAttributes.some(
      attr => attr.accountAttributeTypeId === attributeTypeId,
    )
export const isTechPlatformAccessAccount = isTypeOfAccount(
  platformAccessAccountAttributeTypeId,
)

export const isLendingAccount = (account: AccountInfo) => {
  return (
    isTypeOfAccount(borrowerAccountAttributeTypeId)(account) ||
    isTypeOfAccount(lendingAccountAttributeTypeId)(account)
  )
}

export const isTradingAccount = isTypeOfAccount(tradingAccountAttributeTypeId)
export const isMarginTradingAccount = isTypeOfAccount(
  marginTradingAccountAttributeTypeId,
)
export const isPlatformAccessAccount = isTypeOfAccount(
  platformAccessAccountAttributeTypeId,
)

export const getG1Accounts = (accounts: AccountInfo[]) => {
  return accounts?.filter(account => account.isG1Account)
}
