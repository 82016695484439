import React, { ReactNode, ReactElement } from 'react'
import cx from 'classnames'
import { GridTableColumnResizer } from './GridTableColumnResizer'
import { getCellClass, getCellStyle } from './util'

type Props = {
  className?: string
  children: ReactNode
  col: any
  row: any
  sortAsc?: (a: any, b: any) => number
  updateColumnWidth: (width: number) => void
  resizable?: boolean
  filterComponent?: ReactElement
  sortComponent?: ReactElement
  hasFilterApplied?: boolean
  hasSortApplied?: boolean
  getFilterValue?: Function
} & React.HTMLAttributes<HTMLDivElement>

export const GridTableHeaderCell = React.memo<Props>(props => {
  const {
    col,
    children,
    updateColumnWidth,
    filterComponent,
    sortComponent,
    sortAsc,
    resizable = true,
    hasFilterApplied,
    hasSortApplied,
    row,
    style = {},
    ...htmlProps
  } = props

  const className = getCellClass(row, col, null)

  let { headerClass } = col
  if (Array.isArray(headerClass)) {
    headerClass = cx(...headerClass)
  } else if (headerClass instanceof Function) {
    headerClass = headerClass({ row, col, colDef: col, data: row })
  }

  return (
    <div
      className={cx('grid-table-cell', {
        [className]: !!className,
        [headerClass]: !!headerClass,
        'has-filter': !!filterComponent,
        'has-filter-applied': hasFilterApplied,
        'has-sort': !!sortComponent,
        'has-sort-applied': hasSortApplied,
      })}
      style={{
        ...style,
        ...getCellStyle(row, col),
      }}
      {...htmlProps}
    >
      {filterComponent}
      <span className="header">
        {props.children}
        {sortComponent}
      </span>
      {resizable ? (
        <GridTableColumnResizer updateColumnWidth={props.updateColumnWidth} />
      ) : null}
    </div>
  )
})
