import { useEffect, MutableRefObject } from 'react'
import { useEffectNow } from 'hooks/useEffectNow'
import { useStateRef } from 'hooks/useStateRef'
import { useUpdatingRef } from 'hooks/useUpdatingRef'

export const useUpdatingState = <T>(
  initial?: T | (() => T),
  onChange?: (value: MutableRefObject<T>) => void,
): [T, (value: T) => void, MutableRefObject<T>] => {
  const [ref, set] = useStateRef<T>(initial)
  const onChangeRef = useUpdatingRef(onChange)
  const triggerOnChangeRef = useUpdatingRef(false)
  useEffectNow(() => {
    if (initial instanceof Function) {
      ref.current = initial()
    } else {
      ref.current = initial
    }
    triggerOnChangeRef.current = true
  }, [ref, initial])

  useEffect(() => {
    if (triggerOnChangeRef.current) {
      triggerOnChangeRef.current = false
      onChangeRef.current && onChangeRef.current(ref)
    }
  })
  return [ref.current, set, ref]
}
