import React, { FC } from 'react'

import Secure from 'components/Secure'
import StoreGlobalDispatch from 'Shared'

import { AppContent } from '../AppContent'

type Props = {
  appHeader: React.ReactElement
  page: React.ReactElement
}

export const SecuredContent: FC<Props> = ({ appHeader, page }) => {
  return (
    <>
      <StoreGlobalDispatch />
      <Secure>
        <AppContent appHeader={appHeader} page={page} />
      </Secure>
    </>
  )
}
