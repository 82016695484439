import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, Button } from 'semantic-ui-react'
import Modal from 'components/Modal'
import useAuthUser from 'hooks/useAuthUser'
import { useStateRef } from 'hooks/useStateRef'
import { Strategy } from 'redux/models'
import getEmail from 'redux/selectors/getEmail'
import getSelectedStrategyID from 'redux/selectors/getSelectedStrategyID'
import { mapToOpt } from 'utils/dropdown-util'
import { emptyArray } from 'utils/emptyArray'
import { getState } from 'utils/getState'
import { post } from 'utils/http'
import Styles from './styles.module.scss'

const baseUrl = process.env.NEXT_PUBLIC_POSITION_REPORT_SERVICE

type Props = {
  onClose: Function
  open: boolean
}

function sendReport(strategyId, onSuccess, onError) {
  post(
    baseUrl,
    `snapshots/strategies/${strategyId}`,
    [getEmail(getState())],
    onSuccess,
    onError,
    false, // no authorization
  )
}

const PositionReportDialog = React.memo((props: Props) => {
  const [selectedStrategiesRef, setSelectedStrategies] = useStateRef([
    getSelectedStrategyID(getState()),
  ])
  const { strategies = emptyArray } = useAuthUser('internal')
  const email = useSelector(getEmail)

  const availableStrategies = useMemo(() => {
    const strats: Strategy[] = []
    strategies.forEach(strat => {
      strats.push(strat)
      if (strat.subStrategies) {
        strat.subStrategies.forEach(strat => {
          strats.push(strat)
        })
      }
    })
    return strats.map(strat => mapToOpt(strat.name, strat.id))
  }, [strategies])

  const submit = useCallback(() => {
    selectedStrategiesRef.current.forEach(key => {
      sendReport(key, props.onClose, () => {
        alert('Backend Error')
      })
    })
  }, [props.onClose])

  return (
    <Modal
      header={'Position Reporting'}
      onClose={props.onClose as any}
      open={props.open}
      size="large"
      actions={useMemo(
        () => [
          <Button key="cancel" size="small" onClick={props.onClose as any}>
            Cancel
          </Button>,
          <Button positive key="cancel" size="small" onClick={submit as any}>
            Submit
          </Button>,
        ],
        [],
      )}
    >
      <div>
        <div className={Styles.inputs}>
          <div>
            <div>Strategies:</div>
            <Dropdown
              value={selectedStrategiesRef.current}
              options={availableStrategies}
              multiple
              onChange={useCallback((_, { value }) => {
                setSelectedStrategies(value as number[])
              }, [])}
            />
          </div>
        </div>
        <div>This report will be sent to {email}</div>
      </div>
    </Modal>
  )
})

PositionReportDialog.displayName = 'PositionReportDialog'
export default PositionReportDialog
